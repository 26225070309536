import Api from '@/services/Api'

export default {
    getTotalizados(data) {
        return Api().get('resultado/totalizado',{params: data})
    },
    getTotalizadosCsv(data) {
        return Api({responseType: 'arraybuffer'}).get('resultado/totalizadocsv',{params: data})
    },
    getTotalizadosNacionalesCsv(data) {
        return Api({responseType: 'stream'}).get('csv/'+ data.año + '_1_' +  data.idEleccion +'.csv')
    },
    get(data) {
        return Api().get('resultado',{params: data})
    }
    
}