<template>
  <v-container
    class="infoCard"
    :class="[color, text_dark ? 'dark--white' : 'white--text']"
  >
    <v-row>
      <v-col cols="2" class="infoCard_icon my-auto pa-0">
        <slot name="icon">
          <v-icon>mdi mdi-{{icon}}</v-icon>
        </slot>
       
      </v-col>
      <v-col cols="10" class="infoCard_content">
        <div class="infoCard__header">{{title}}</div>
        <div class="infoCard__body">
          <span v-if="!loading">{{description}}</span>
          <v-skeleton-loader v-else class="pt-2" max-width="40px" type="text"></v-skeleton-loader>
        </div>
        <div class="infoCard__footer"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script >
import Vue from "vue";

export default Vue.extend({
  name: "Infocard",

  props: {
    color: {
      type: String,
      default: "primary",
      validator: (val) =>
        ["primary", "secondary", "dark", "magenta", "tertiary","lilac"].includes(val),
    },
    title:String,

    description: String || Number,
    
    icon: {
      type:String,
      required:false
    },

    loading: {
      type:Boolean,
      default:false
    },


    text_dark: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    //
  }),
});
</script>
<style scoped>
.infoCard {
  padding: 1rem;
  font-weight: bold;
  border-radius: 10px;
  height: 100%;
}

.infoCard__body {
  font-weight: normal;
}
</style>