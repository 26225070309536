<template>
    <v-container>
        <v-row>
            <span class="">▶</span>
            <v-spacer class="spacer-line"></v-spacer>
            <span class="text-center font-weight-bold ">
                <slot ></slot>
            </span>
            <v-spacer class="spacer-line"></v-spacer>
            <span>◀</span>
        </v-row>
    </v-container>
</template>
<script>


export default {
    name: 'divider',


}
</script>

<style>
.spacer-line {
    flex-grow: 1;
    border-bottom: 2px dotted black;
    margin: 0 2px 8px;
}
</style>