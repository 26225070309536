<template>
  <div class="card cardpartido" :style="{ height: customHeight + 'px' }">
    <div class="card_title">
      <v-row style="justify-content: space-between">
        <div style="padding: 18px">
          {{ title }}
        </div>
        <v-btn v-if="agrupaciones && agrupaciones[0].listas[0].idlista" style="padding: 30px" icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-row>
    </div>
    <div class="card_body">
      <v-container v-if="agrupaciones && agrupaciones.length" fluid id="scroll-target"
        :style="{ 'max-height': customHeight + 'px' }" class="overflow-y-auto">
        <v-row :style="{ 'max-height': customHeight - 100 + 'px', margin: '0px' }">
          <template v-for="(item, index) in agrupaciones">
            <divider
              v-if="(($route.params.cargo != 1 && $route.params.cargo != 8) || (($route.params.cargo == 1 || $route.params.cargo == 8) && $route.params.distrito == 0 ) )  && agrupaciones && agrupaciones[0].listas[0].idlista && index > 0 && piso(agrupaciones[index - 1], item)"
              :key="'span' + index">1,5% PISO
              ELECTORAL</divider>
            <card-agrupacion class="my-1" :agrupacion="item" :key="index" :colapsar="show">
            </card-agrupacion>
          </template>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-container>
          <v-row>
            <v-col cols="10" class="text-capitalize">
              <div class="roboto">
                <v-skeleton-loader class="pt-2" max-width="200px" type="text"></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="roboto text-right">
                <v-skeleton-loader class="pt-2" type="text"></v-skeleton-loader>
              </div>
              <div class="roboto text-right">
                <v-skeleton-loader class="pt-2" type="text"></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>

        <v-container>
          <v-row>
            <v-col cols="10" class="text-capitalize">
              <div class="roboto">
                <v-skeleton-loader class="pt-2" max-width="200px" type="text"></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="roboto text-right">
                <v-skeleton-loader class="pt-2" type="text"></v-skeleton-loader>
              </div>
              <div class="roboto text-right">
                <v-skeleton-loader class="pt-2" type="text"></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>

        <v-container>
          <v-row>
            <v-col cols="10" class="text-capitalize">
              <div class="roboto">
                <v-skeleton-loader class="pt-2" max-width="200px" type="text"></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="roboto text-right">
                <v-skeleton-loader class="pt-2" type="text"></v-skeleton-loader>
              </div>
              <div class="roboto text-right">
                <v-skeleton-loader class="pt-2" type="text"></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </v-container>
    </div>
    <div class="card_footer"></div>
  </div>
</template>

<script >
import cardAgrupacion from "../cardsLista/cardAgrupacion.vue";

import divider from "../dashboard/Divider.vue";

export default {
  name: "cardPartido",
  components: { cardAgrupacion, divider },
  props: {
    title: {
      type: String,
      default: "",
    },
    agrupaciones: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: Number,
      default: 590,
    },
  },

  data: () => ({
    show: false,
  }),
  created() {
    // this.show = !this.$vuetify.breakpoint.mobile;
    this.show = true;

  },

  mounted() {
    // console.log(this.agrupaciones);
  },

  methods: {
    piso(prev, sig) {
      return parseFloat(prev.porcentaje) >= 1.5 && parseFloat(sig.porcentaje) < 1.5
    }
  },
};
</script>
<style></style>