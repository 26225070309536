<template>
  <v-app class="background">
    <Header />
    <Filters v-if="$vuetify.breakpoint.lgAndUp" />
    <Filtersmobile v-if="$vuetify.breakpoint.mdAndDown" />

    <v-main class="main_mb">
      <router-view />
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Filters from "./Filters.vue";
import Filtersmobile from "./Filtersmobile.vue";

export default {
  components: { Header, Footer, Filters,Filtersmobile },
};
</script>

<style>
.main_mb {
  margin-bottom: 5rem;
}
</style>