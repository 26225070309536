import axios from 'axios'
import store from '@/store'
import router from '../router'


export default (params = null) => {

    let options = {
        baseURL: process.env.VUE_APP_BASE_URL,
        // baseURL:'http://localhost:8080/' ,
        // baseURL:'https://op.elecciones.gob.ar/api/' ,
        
        headers: {
        }
    }

    params && (options = { ...options , ...params})

    let instance = axios.create(options)

    return instance

}
