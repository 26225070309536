<template>
  <router-link
    class="link-unstyled"
    :to="{
      name: 'Resultados',
      params: { ...data, distrito: distrito.IdDistrito },
    }"
  >
    <div class="card cardprovincia">
      <div class="cardprovincia_header">
        {{ distrito.Distrito }}
      </div>
      <div class="cardprovincia_body text-center">
        <v-container fluid>
          <v-row>
            <!-- <v-img contain aspect-ratio="1.5" src="@/assets/images/jujuy.png"/> -->
            <!-- <v-col v-html="svg"> </v-col> -->
            <l-map
              ref="mapControl"
              :zoom="zoom"
              :center="center"
              :key="key"
              :options="{
                zoomControl: false,
                attributionControl: false,
                scrollWheelZoom: false,
                dragging:false
              }"


              style="height: 200px; width: 100%"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <l-geo-json
                v-if="show"
                :geojson="geojson"
                :options-style="styleFunction"
              />
              <!-- <l-marker :lat-lng="marker" /> -->
            </l-map>
          </v-row>
        </v-container>
      </div>
      <div class="cardprovincia_footer"></div>
    </div>
  </router-link>
</template>

<script >
import Vue from "vue";
//@ts-ignore
import { latLng, featureGroup } from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import provinciasJson from "@/json/Provincias_geo.json";
// import { latLng,  } from "leaflet";
// import cabaJson from "@/json/capital.json";

// import geojson2svg, { Renderer } from "geojson-to-svg";

import { mapState } from "vuex";

export default {
  name: "Cardprovincia",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    // LMarker,
  },
  props: {
    distrito: {
      type: Object,
    },
    data: {
      type: Object,
    },
  },

  data: () => ({
    attributes: [],
    provincias: provinciasJson,
    svg: "",
    svgstr: "",

    key:0,
    //

    loading: false,
    show: true,
    enableTooltip: true,
    zoom: 3,
    center: [48, -1.219482],
    geojson: null,
    fillColor: "#e4ce7f",
    url: "",//"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "",
    marker: latLng(47.41322, -1.219482),
  }),

  created() {
    if (this.mapa && this.distrito) {
      this.getMapa()
    }

  },

  computed: {
    ...mapState(["mapas"]),

    mapa() {
      if (this.mapas) {
        return this.mapas.find(
          (mapa) =>
            mapa.ID_INDRA == this.distrito.IdDistrito.toString().padStart(2, 0)
        );
      } else {
        return [];
      }
    },

    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        // console.log('ENTRO ACA');
        this.paso++;
        return {
          weight: 2,
          color: "#18a0fb",
          opacity: 1,
          fillColor: this.paso % 2 == 1 ? "#18a0fb" : "#18a0fb",
          fillOpacity: 1,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return {}; //() => {};
      }
      return (feature, layer) => {
        // console.log('layer',layer);
        // layer.options.color = this.fillColor
        // layer.options.fillColor = this.fillColor

        layer.bindTooltip(
          "<div>Distrito:" +
            feature.properties.PROVINCIA +
            "</div>",
          { permanent: false, sticky: true }
        );
      };
    },
  },

  methods: {
    getMapa () {
            let allx = this.mapa.features[0].geometry.coordinates[0].map((x) => x[0]);
      let minx = Math.min.apply(null, allx);
      let maxx = Math.max.apply(null, allx);
      let centerx = (maxx - minx) / 2 + minx;

      let ally = this.mapa.features[0].geometry.coordinates[0].map((x) => x[1]);
      let miny = Math.min.apply(null, ally);
      let maxy = Math.max.apply(null, ally);
      let centery = (maxy - miny) / 2 + miny;


      this.center = [centery, centerx];
      this.geojson = this.mapa;

      this.$nextTick().then(() => {
        var group = new featureGroup();

        this.$refs.mapControl.mapObject.eachLayer(function (layer) {
          if (layer.feature != undefined) {
            group.addLayer(layer);
            // layer.dragging.disable();
          }
        });

        this.$refs.mapControl.mapObject.fitBounds(group.getBounds(), {
          padding: [20, 20],
        });
      });
    }
  },

  watch: {
    distrito() {

      this.key++;
      this.getMapa()
    }
  }


};
</script>
<style lang="scss">
.cardprovincia {
  background-color: white;
  padding: 1rem 1rem 1rem 1rem;

  .cardprovincia_header {
    font-weight: bold;
    line-height: 1.5em;
    height: 3em;
    text-align: center;
  }
}
</style>