<template>
  <div class="card cardpartido">
    <div class="card_title">
      <v-row style="justify-content: space-between">
        <div class="px-2 py-1">
          {{ title }}
        </div>
        <v-btn v-if="agrupaciones && agrupaciones[0].listas[0].idlista" class="px-2 py-1" icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-row>
    </div>
    <div class="card_body">
      <v-container v-if="agrupaciones && agrupaciones.length" fluid id="scroll-target">
        <v-row>

          <template v-for="(item, index) in agrupaciones">
            <divider
              v-if="((cargo != 1 && cargo != 8) || ((cargo == 1 || cargo == 8) && distrito == 0 ) )  &&  agrupaciones && agrupaciones[0].listas[0].idlista && index > 0 && piso(agrupaciones[index -1],item)"
              :key="'span'+ index">1,5% PISO
              ELECTORAL</divider>
            <AgrupacionInforme class="my-1 px-2 py-1"  :agrupacion="item" :key="index" :colapsar="show">
            </AgrupacionInforme>
          </template>
          <!-- <AgrupacionInforme
            class="my-1 px-2 py-1"
            v-for="(item, index) in agrupaciones"
            :key="index"
            :agrupacion="item"
            :colapsar="show"
          >
          </AgrupacionInforme> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script >
import AgrupacionInforme from "@/components/informe/AgrupacionInforme.vue";
import divider from "../dashboard/Divider.vue";

export default {
  name: "partidoinforme",
  components: { AgrupacionInforme,divider },
  props: {

    cargo: {
      type: Number,
    }, 
    distrito: {
      type:Number
    }, 
    title: {
      type: String,
      default: "",
    },
    agrupaciones: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: Number,
      default: 550,
    },
  },

  data: () => ({
    show: true,
  }),


  methods: {
    piso(prev, sig) {
      return parseFloat(prev.porcentaje) >= 1.5 && parseFloat(sig.porcentaje) < 1.5
    }
  },
};
</script>
<style>

</style>