<template>
  <div class="dark pa-0">
    <v-container fluid class="pa-0 ">
      <v-row no-gutters class="py-1">
        <!-- <v-select filled label="Año">sdf</v-select> -->
        <v-col class="align-self-center  py-0" >
          <v-select
            :items="periodos"
            placeholder="Año"
            v-model="periodo"
            hide-details
            dense
            no-data-text="Seleccione valor al filtro anterior"
            dark
            solo
            color="white"
            background-color="dark"
            item-color="dark"
            class="filter-style text-no-wrap"
          >
            <template v-slot:selection="{ item }">
              <span
                class="
                  d-flex
                  justify-end
                  text-no-wrap
                  institucional--text
                  font-weight-bold
                
                "
                style="width: 100%"
              >
                {{ item | capitalize }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col class="align-self-center  py-0" >
          <v-select
            :items="elecciones"
            placeholder="Elecciones"
            v-model="eleccion"
            hide-details
            dense
            no-data-text="Seleccione valor al filtro anterior"
            dark
            solo
            color="white"
            background-color="dark"
            item-color="dark"
            class="filter-style"
          >
            <template v-slot:selection="{ item }">
              <span
                class="
                  d-flex
                  justify-end
                  text-no-wrap
                  institucional--text
                  font-weight-bold
                "
                style="width: 100%"
              >
                {{ item.text | capitalize }}
              </span>
            </template>
          </v-select>
        </v-col>

        <v-col class="align-self-center  py-0" >
          <v-select
            :items="cargos"
            placeholder="Cargo"
            v-model="cargo"
            hide-details
            dense
            no-data-text="Seleccione valor al filtro anterior"
            dark
            solo
            color="white"
            background-color="dark"
            item-color="dark"
            class="filter-style"
          >
            <template v-slot:selection="{ item }">
              <span
                class="
                  d-flex
                  justify-end
                  text-no-wrap
                  institucional--text
                  font-weight-bold
                "
                style="width: 100%"
              >
                {{ item.text | capitalize }}
              </span>
            </template>
          </v-select>
        </v-col>

        <v-col class="align-self-center  py-0" >
          <v-select
            :items="distritos"
            placeholder="Distrito"
            v-model="distrito"
            hide-details
            dense
            no-data-text="Seleccione valor al filtro anterior"
            dark
            solo
            color="white"
            background-color="dark"
            item-color="dark"
            class="filter-style"
          >
            <template v-slot:selection="{ item }">
              <span
                class="
                  d-flex
                  justify-end
                  text-no-wrap
                  institucional--text
                  font-weight-bold
                "
                style="width: 100%"
              >
                {{ item.text | capitalize }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
        
          class="align-self-center  py-0"
          v-show="seccionesElectorales.length"
        >
          <v-select
            :items="seccionesElectorales"
            placeholder="Sección electoral"
            v-model="seccionElectoral"
            hide-details
            dense
            dark
            no-data-text="Seleccione valor al filtro anterior"
            solo
            color="white"
            background-color="dark"
            item-color="dark"
            class="filter-style text-no-wrap"
          >
            <template v-slot:selection="{ item }">
              <span
                class="
                  d-flex
                  justify-end
                  text-no-wrap
                  institucional--text
                  font-weight-bold
                "
                style="width: 100%"
              >
                {{ item.text | capitalize }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col class="align-self-center  py-0" >
          <v-select
            :items="secciones"
            placeholder="Sección"
            v-model="seccion"
            hide-details
            no-data-text="Seleccione valor al filtro anterior"
            dense
            dark
            solo
            color="white"
            background-color="dark"
            item-color="dark"
            class="filter-style"
          >
            <template v-slot:selection="{ item }">
              <span
                class="
                  d-flex
                  justify-end
                  text-no-wrap
                  institucional--text
                  font-weight-bold
                "
                style="width: 100%"
              >
                {{ item.text | capitalize }}
              </span>
            </template>
          </v-select>
        </v-col>

        <!-- <v-spacer></v-spacer> -->

        <v-col cols="12" md="4"  xl="3" class="align-self-center text-center">
          <v-btn @click="filter()" class="mr-2" rounded small>
            <v-icon>mdi-magnify</v-icon>
            Filtrar
          </v-btn>

            <v-btn @click="cleanFilter() && $router.push('/')" outlined rounded color="white" small>
              <v-icon>mdi-broom</v-icon>
              Limpiar filtros
            </v-btn>

            <v-btn @click="setInformeModal(true)" class="ml-2" outlined rounded color="white" small>
              informes
            </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script >
import menuService from "@/services/MenuService";
// import periodosService from "@/services/PeriodosService";
// import store from "@/store";
// import Vue from "vue";
import { mapState,mapActions } from "vuex";
import router from "@/router";
import store from "@/store";

export default {
  name: "Filters",

  data: () => ({
    periodo: "",
    eleccion: "",
    cargo: "",
    distrito: "",
    seccionElectoral: "",
    seccion: "",
    circuito: "",

    menu: [],
    //
  }),

  created() {
    if (this.$route.params.periodo) {
      this.periodo = Number(this.$route.params.periodo);
    }
  },
  computed: {
    ...mapState(["periodos"]),

    secciones() {
      if (this.distritos.length && this.distrito) {
        let secProvinciales = this.menu
          .find((m) => m.IdEleccion == this.eleccion)
          ?.Cargos.find((c) => c.IdCargo == this.cargo)
          .Distritos.find(
            (d) => d.IdDistrito == this.distrito
          ).SeccionesProvinciales;
        if (secProvinciales && secProvinciales.length > 1) {
          if (this.seccionElectoral) {
            return secProvinciales
              .find((sp) => sp.IDSeccionProvincial == this.seccionElectoral)
              .Secciones.map((s) => {
                return {
                  value: s.IdSeccion,
                  text: s.Seccion,
                };
              })
              .sort(this.sortSelect);
          }
        } else {
          return secProvinciales[0].Secciones.map((s) => {
            return {
              value: s.IdSeccion,
              text: s.Seccion,
            };
          }).sort(this.sortSelect);
        }
      }

      return [];
    },

    //retora listado de secciones electorales con value y text
    seccionesElectorales() {
      if (this.cargos.length && this.cargo && this.eleccion && this.distrito) {
        let distrito = this.menu
          .find((m) => m.IdEleccion == this.eleccion)
          ?.Cargos.find((c) => c.IdCargo == this.cargo)
          .Distritos.find((d) => d.IdDistrito == this.distrito);
        // return seccionesElectorales ? seccionesElectorales.
        return distrito
          ? distrito.SeccionesProvinciales.filter((sp) => {
              return sp.IDSeccionProvincial;
            })
              .map((sp) => {
                return {
                  value: sp.IDSeccionProvincial,
                  text: sp.SeccionProvincial,
                };
              })
              .sort(this.sortSelectValue)
          : [];
      }
      return [];
    },

    //retora listado de secciones electorales con value y text

    distritos() {
      if (this.cargos.length && this.cargo && this.eleccion) {
        let cargo = this.menu
          .find((m) => m.IdEleccion == this.eleccion)
          ?.Cargos.find((c) => c.IdCargo == this.cargo);

        return cargo
          ? cargo.Distritos.map((d) => {
              return {
                value: d.IdDistrito,
                text: d.Distrito,
              };
            })
          : [];
      } else {
        return [];
      }
    },

    //retorno el listado de elecciones para el periodo (si existe)
    elecciones() {

      if (this.menu.length) {
        let elecciones = [];
        elecciones = this.menu.map((menu) => {
          return {
            value: menu.IdEleccion,
            text: menu.Elecciones,
          };
        });

        elecciones.sort ((a,b) => {
          if (a.value > b.value) {
            return -1
          }else {
            return 1
          }

        })


        return elecciones;
      } else {
        return [];
      }
    },

    //retorno el listado de cargos para el periodo y eleccion
    cargos() {
      if (this.menu.length) {
        if (this.eleccion) {
          let eleccion = {};
          let cargos;
          eleccion = this.menu.find((menu) => menu.IdEleccion == this.eleccion);
          cargos = eleccion.Cargos.map((cargo) => {
            return {
              value: Number(cargo.IdCargo),
              text: cargo.Cargo,
            };
          });

          return cargos.sort((a, b) => a.value - b.value);
        }
        return [];
      } else {
        return [];
      }
    },
  },

  watch: {
    $route(to) {
      to.path == "/" && this.cleanFilter();
    },
    //watcheo de el cambio de el valor periodo . para actualizar la peticion al resto del menu
    periodo: async function (act, prev) {
      if (prev ) {
        this.eleccion = ""
      } 
      this.menu = await this.getMenu({
        año: this.periodo,
      });
      // if (this.menu) this.eleccion = "";
    },

    eleccion(act, prev) {
      if (!(act == this.$route.params.eleccion && prev == "")) {
        this.cargo = "";
      }
    },

    cargo(act, prev) {
      if ((act ==  1 || act ==  8) && this.distrito == "") {
        this.distrito = 0;
      }else if (!(act == this.$route.params.cargo && prev == "")) {
        this.distrito = "";
      }
    },

    distrito(act, prev) {
      if (
        !(act == this.$route.params.distrito && prev == "") &&
        this.seccionElectoral != ""
      ) {
        this.seccionElectoral = "";
      }

      if (!(act == this.$route.params.distrito && prev == "")) {
        this.seccion = "";
      }
    },

    seccionElectoral(act, prev) {
      if (!(act == this.$route.params.seccionProvincial && prev == "")) {
        this.seccion = "";
      }
    },

    "$route.params": {
      handler: function () {
        // console.log("params", this.$route.params);
        if (this.$route.params.periodo)
          this.periodo = Number(this.$route.params.periodo);
        if (this.$route.params.eleccion)
          this.eleccion = Number(this.$route.params.eleccion);
        if (this.$route.params.cargo)
          this.cargo = Number(this.$route.params.cargo);
        if (this.$route.params.distrito)
          this.distrito = Number(this.$route.params.distrito);
        if (this.$route.params.seccionProvincial)
          this.seccionElectoral = Number(this.$route.params.seccionProvincial);
        if (this.$route.params.seccion)
          this.seccion = Number(this.$route.params.seccion);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {

    ...mapActions(["setInformeModal"]),

    filter() {
      if (this.periodo) {
        if (this.seccion) {
          if (this.seccionElectoral) {
            router.push({
              name: "Resultados",
              params: {
                periodo: parseInt(this.periodo),
                eleccion: parseInt(this.eleccion),
                cargo: parseInt(this.cargo),
                distrito: parseInt(this.distrito),
                seccionProvincial: parseInt(this.seccionElectoral),
                seccion: parseInt(this.seccion),
                // menu: this.menu,
              },
            });
          } else {
            router.push({
              name: "Resultados",
              params: {
                periodo: parseInt(this.periodo),
                eleccion: parseInt(this.eleccion),
                cargo: parseInt(this.cargo),
                distrito: parseInt(this.distrito),
                seccionProvincial: 0,
                seccion: parseInt(this.seccion),
                // menu: this.menu,
              },
            });
          }
        } else if (this.seccionElectoral) {
          router.push({
            name: "Resultados",
            params: {
              periodo: parseInt(this.periodo),
              eleccion: parseInt(this.eleccion),
              cargo: parseInt(this.cargo),
              distrito: parseInt(this.distrito),
              seccionProvincial: parseInt(this.seccionElectoral),
              // menu: this.menu,
            },
          });
        } else if (this.distrito?.toString()) {
          router.push({
            name: "Resultados",
            params: {
              periodo: parseInt(this.periodo),
              eleccion: parseInt(this.eleccion),
              cargo: parseInt(this.cargo),
              distrito: parseInt(this.distrito),
              // menu: this.menu,
            },
          });
        } else if (this.cargo) {
          // console.log(this.cargo);
          router.push({
            name: "Categorias",
            params: {
              periodo: parseInt(this.periodo),
              eleccion: parseInt(this.eleccion),
              cargo: parseInt(this.cargo),
              // menu: this.menu,
            },
          });
        } else {
          router.push({
            name: "Elecciones",
            params: { periodo: this.periodo },
          });
        }
      }
    },

    cleanFilter() {
      this.periodo = "";
      this.eleccion = "";
      this.cargo = "";
      this.distrito = "";
      this.seccionElectoral = "";
      this.seccion = "";
      return true
    },

    sortSelect(a, b) {
      if (a.text > b.text) {
        return 1;
      }
      if (b.text > a.text) {
        return -1;
      }
      return 0;
    },
    sortSelectValue(a, b) {
      if (a.value > b.value) {
        return 1;
      }
      if (b.value > a.value) {
        return -1;
      }
      return 0;
    },

    getMenu: async (data) => {
      try {
        let dataFiltros = await menuService.get(data);
        store.commit("setMenu", dataFiltros.data);
        return dataFiltros.data;
        // console.log(menu);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
};
</script>

<style>
.filter-style .v-input__slot {
  box-shadow: none !important;
}

.v-text-field input::placeholder {
  color: white !important;
  opacity: 1;
  text-align: right;
}

.menuable__content__active {
  z-index: 1000 !important;
}
</style>