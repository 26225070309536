import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import Home from '../views/Home.vue'



import Dashboard from "@/components/dashboard/Dashboard.vue";
import Desarrollo from "@/components/seccionDesarrolladores/Inicio.vue";

// import Elecciones from "@/views/Elecciones.vue";
import Categorias from "@/views/Categorias.vue";
// import Categoria from "@/components/Categoria.vue";
import Resultados from "@/views/Resultados.vue";
// import Informe from "@/components/informe/Informe.vue";
import InformeVue from '@/components/informe/Informe.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // {
  //   path: '/informe/:periodo/:eleccion/:cargo/:distrito/:seccionProvincial?/:seccion?',
  //   component: Informe,
  // },
  {
    path: '/informes',
    name: 'informes',
    component: InformeVue,
    props: true
  },
  {
    path: '/desarrollo',
    name: 'Desarrollo',
    component: Desarrollo,
  },
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: "/:periodo?",
        alias: "elecciones",
        name: "Elecciones",
        component: () => import("@/views/Elecciones.vue"),

      },
      {
        path: "resultados/:periodo/:eleccion/:cargo/:distrito/:seccionProvincial?/:seccion?",
        name: "Resultados",
        component: Resultados
      },
      {
        path: "categorias/:periodo/:eleccion/:cargo?",
        name: "Categorias",
        component: Categorias,
        // children: [
        //   {
        //     path:"",
        //     name:"Categoria",
        //     component:Categoria,

        //   }

        // ]
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == 'Elecciones' && to.params.periodo && !isNumber(to.params.periodo)) {
    next(false);
    router.push('/')
  }
  next();
})

const isNumber = (value?: string | number): boolean => {
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

export default router
