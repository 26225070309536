<template>
  <v-container fluid class="mt-10">
    <v-row>
      <v-col xl="4" cols="12">
        <div class="font-weight-bold text-h4">
          <span
            class="span_title"
            v-for="(title, index) in titles"
            :key="index"
          >
            {{ title }}
          </span>
        </div>
        <div class="text-subtitle-1">
          <span v-for="(subtitle, index) in subtitles" :key="index">
            {{ subtitle | capitalize }}
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        class="pa-5"
        v-for="(distrito, index) in distritos"
        :key="index"
      >
        <Cardprovincia :data="$route.params" :distrito="distrito">
        </Cardprovincia>
      </v-col>
    </v-row>
  </v-container>
</template>

<script >
// import Vue from "vue";
import menuService from "@/services/MenuService";
// import MapasService from "@/services/MapasService";
import Cardprovincia from "@/components/CardProvincia.vue";
// import router from "@/router";

export default {
  name: "Categoria",
  components: { Cardprovincia },


  data: () => ({
    data: [],
    datamapa: [],
  }),

  computed: {
    distritos() {
      if (this.data && this.data.length) {
        return this.data[0]["Cargos"].find(
          (c) => c.IdCargo == this.$route.params.cargo
        ).Distritos;
      } else {
        return [];
      }
    },

    titles() {
      if (this.data && this.data.length) {
        let aux = [];
        aux.push("Elecciones " + this.periodo);
        aux.push(this.data[0]["Elecciones"]);

        return aux;
      } else {
        return [];
      }
    },

    subtitles() {
      if (this.data && this.data.length) {
        let aux = [];
        aux.push("Resultados " + this.data[0]["Recuento"]+'s');
        aux.push(this.cargo);
        return aux;
      } else {
        return [];
      }
    },

    periodo() {
      return this.$route.params.periodo;
    },

    eleccion () {
      return this.$route.params.eleccion
    },

    cargo() {
      if (this.data && this.data.length) {
        return this.data[0]["Cargos"].find(
          (c) => c.IdCargo == this.$route.params.cargo
        ).Cargo;
      } else {
        return "";
      }
    },
  },

  created() {
    // console.log("params", this.$route.params);
    this.initData();
  },

  methods: {
    initData() {
      this.getMenu({
        año: this.$route.params.periodo,
        recuento: "Provisorio",
        idEleccion: this.$route.params.eleccion,
        idCargo: this.$route.params.cargo,
      }).then((data) => {
        this.data = data.datafiltro;
        // this.datamapa = data.dataMapaActual
      });
    },

    getMenu: async (data) => {
      try {
        let dataFiltros = await menuService.get(data);
        // let dataMapaActual = await(await MapasService.getAllContornos({ minimizado:true }));
        return { datafiltro: dataFiltros.data };
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  watch: {
    // "$route.params.periodo": {
    //   handler: function (search) {
    //     this.initData();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // "$route.params.recuento": {
    //   handler: function (search) {
    //     this.initData();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    "$route.params.eleccion": {
      handler: function (search) {
        this.initData();
      },
     
    },
  },
};
</script>

<style lang="scss">
.span_title {
  &::after {
    content: "|";
  }
  &:last-child::after {
    content: "";
  }
}
</style>