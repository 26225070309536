<template>
  <div class="card cardvotos" :style="{ height: customHeight + 'px' }">
    <div class="card_title">
      {{ title }}
    </div>
    <div class="card_body">
      <div id="chart" :style="{width: width}">
        <apexchart :height="(customHeight - 50) +'px'" type="donut" :options="chartOptions" :series="valores">
        </apexchart>
      </div>
      <!-- <div class="legends">
        <div v-for="(voto,index) in  votos" :key="index" class="legend d-flex align-content-center flex-wrap">
          <v-avatar :color="chartOptions.colors[index]" size="25"> </v-avatar>
          <span class="pl-2 legend-text align-self-center font-weight-bold">{{voto.nombre}}</span>
        </div>
      </div> -->
    </div>
    <div class="card_footer"></div>
  </div>
</template>

<script>
// import Vue from "vue";

export default {
  name: "VotosInforme",

  props: {
    title: {
      type: String,
      default: "",
    },
    votos: {
      type: Array,
    },
    customHeight: {
      type: Number,
      default: 550,
    },
    votantes: Number,

    width: {
      type: String,
      default: '100%'
    }
  },

  computed: {
    nombres() {
      if (this.votos) {
        return this.votos.map((m) => m.nombre);
      }
      return [];
    },

    valores() {
      if (this.votos) {
        return this.votos.map((m) => m.valor);
      }
      return [];
    },
  },

  data() {
    return {
      series: [],
      chartOptions: {
        labels: [],
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        // },

        colors: ["#50B8B1", "#EE3D8F", "#9283BE", "#F7941E"],
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "5px",
                  fontFamily: "Encode Sans",
                  color: "#000",
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Encode Sans",
                  fontWeight: "bold",
                  color: "#000",
                  offsetY: 16,
                  formatter: (val) => {
                    return Number(val).toLocaleString();
                  },
                },
                total: {
                  show: true,
                  fontSize: "22px",
                  label: "Votantes",
                  fontFamily: "Encode Sans",
                  fontWeight: "bold",
                  color: "#000",
                  // formatter: function (w) {
                  //   return w.globals.seriesTotals.reduce(
                  //     (a, b) => {
                  //       return a + b;
                  //     },
                  //     0
                  //   );
                  // },
                  formatter: () => {
                    // console.log('char',votantes);
                    return 0;
                  },
                },
              },
            },
          },
        },
        chart: {
          type: "donut",


          animations: {
            enabled: false,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        legend: {
          position: "bottom",
          floating: false,
          /*
            * @param {string} seriesName - The name of the series corresponding to the legend
            * @param {object} opts - Contains additional information as below
            * opts: {
            *   seriesIndex
            *   w: {
            *     config,
            *     globals  
            *   },
            * }
          */
          formatter: function (seriesName, opts) {
            // console.log(opts.w.globals.series.reduce((prev,sig) => prev + sig, 0));
            return [seriesName, " - ", ((opts.w.globals.series[opts.seriesIndex] * 100) / opts.w.globals.series.reduce((prev,sig) => prev + sig, 0)).toFixed(1) + '%' ]
          }
        },
      },
    };
  },

  created() {
    // this.$nextTick().then(() => {
    //   });
    this.chartOptions.labels = this.nombres;
    this.chartOptions.plotOptions.pie.donut.labels.total.formatter = () => {
      return Number(this.votantes).toLocaleString();
    };
  },
};
</script>
<style>
.cardvotos {
  min-height: 300px;
}

.legend {
  padding: 1em;
}
</style>