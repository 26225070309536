
// import menuService from "@/services/MenuService";
import periodosService from "@/services/PeriodosService";
import Vue from "vue";
import {mapState} from "vuex";
import store from "./store";
import MapasService from "@/services/MapasService";
import Informes from "@/components/Informes.vue";

export default Vue.extend({
  components: { Informes },
  name: "App",

  data: () => ({
    //
  }),

  computed: {

    informeModal: {
      get() {
        return store.state.informeModal;
      },
      set(value) {
        store.commit("setInformeModal", value);
      },
    },


    ...mapState(["periodo","periodos"]),
  },


  created() {
    //crea la instancia por defecto
    this.getPeriodos()
    this.getMapasContornos()
  },

  methods: {
    // async getFiltros(data: any) {
    //   try {
    //     let dataFiltros = await menuService.get(data);

    //     this.menu = dataFiltros.data;
    //     console.log(this.menu);
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // },

    async getMapasContornos () {
      try {
        let datamapas = await MapasService.getAllContornos({ minimizado:true });   
        store.commit ('setMapas',datamapas.data)
      } catch (error) {
        console.log(error);
      }
    },

    async getPeriodos() {
      try {
        let dataPeriodos = await periodosService.get();        
        store.commit ('setPeriodos',dataPeriodos.data)
      } catch (error) {
        console.log(error);
      }
    },
  },
});
