<template>
  <v-container>
    <v-row @click="collapse = !collapse">
      <v-col class="text-capitalize">
        <div class="roboto">
          {{ agrupacion.nombre }}
        </div>
      </v-col>
      <v-col>
        <div class="roboto text-right">{{ agrupacion.porcentaje }}%</div>
        <div class="roboto text-right">
          {{ Number(agrupacion.votos).toLocaleString() }} votos
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mt-0"
      v-if="!(agrupacion && agrupacion.listas && agrupacion.listas[0].idlista)"
    >
      <v-col cols="12" class="py-1">
        <v-progress-linear
          :value="agrupacion.porcentaje"
          rounded
          :color="agrupacion.color"
          height="15"
        ></v-progress-linear>
      </v-col>
      <v-col cols="12" class="mb-1 py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row
      class="mb-0"
      v-if="agrupacion && agrupacion.listas && agrupacion.listas[0].idlista"
    >
      <v-col>
        <template>
          <v-divider></v-divider>
          <v-expand-transition>
            <div v-show="collapse" class="mt-2">
              <partidoProgress
                v-for="(lista, index) in agrupacion.listas"
                :key="index"
                :partido="lista"
                :partidos="agrupacion.listas"
                :color="agrupacion.color"
              />
            </div>
          </v-expand-transition>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import partidoProgress from "@/components/cardsLista/partidoProgress.vue";
export default {
  name: "AgrupacionInforme",
  components: { partidoProgress },
  data() {
    return {
      show: !this.$vuetify.breakpoint.mobile,
      collapse: false,
    };
  },
  props: {
    agrupacion: {
      type: Object,
    },
    colapsar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},

  created() {
    this.collapse = this.colapsar;
  },

  watch: {
    colapsar() {
      this.collapse = this.colapsar;
    },
  },
};
</script>

<style>
</style>