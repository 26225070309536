var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"primary pa-0 ma-0",attrs:{"fluid":""}},[_c('v-container',[_c('v-row',[_c('v-col',{class:{ 'd-flex child-flex': !_vm.$vuetify.breakpoint.mobile },attrs:{"cols":"6","md":"4","lg":"3"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"max-height":"35","contain":"","aspect-ratio":"1","src":require("@/assets/images/Logo_DINE_negativo.png")}})],1),_c('v-spacer')],1),_c('v-spacer'),_c('v-col',{staticClass:"d-none d-lg-block align-self-center",attrs:{"md":"4"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":this.$route.name !== 'Desarrollo' ? '' : '/'}},[_c('div',{class:{
                'white--text text-center text-md-right mr-5': true,
                'font-weight-bold': this.$route.name !== 'Desarrollo'
              },staticStyle:{"font-size":"1.3rem"}},[_vm._v(" Resultados ")])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/desarrollo"}},[_c('div',{class:{
                'white--text text-center text-md-right ': true,
                'font-weight-bold': this.$route.name == 'Desarrollo'
              },staticStyle:{"font-size":"1.3rem"}},[_vm._v(" Desarrolladores/as ")])])],1)],1)],1),_c('v-col',{staticClass:"d-lg-none d-flex justify-end align-self-end text-right",attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"white","size":"30"},on:{"click":_vm.redirect}},[_vm._v("mdi-magnify")]),_c('v-img',{attrs:{"max-height":"30","max-width":"30","src":require("@/assets/images/api.png")},on:{"click":_vm.redirectFromDevelop}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }