<template>
  <div class="card cardmapa" :style="{ height: customHeight + 'px' }">
    <div class="card_title" style="background: transparent;">
      {{ title }}
      <!-- <img id="imgMap" >
        {{ imageMap }} -->
      <!-- </img> -->
      <v-img id="imgMap" :src="imageMap">

      </v-img>
      <!-- <v-img id="img-map" class="img-map" :src="imageMap"> </v-img> -->

    </div>
    <div class="card_body mapaInforme">
      <l-map id="mapControl" ref="mapControl" v-if="show" :zoom="zoom" :options="{
        zoomControl: zoomOptions,
        attributionControl: false,
        scrollWheelZoom: false,
        preferCanvas: true,
        dragging: !$vuetify.breakpoint.mobile,
      }" :style="{ height: customHeight - 60 + 'px', width: customHeight - 60 + 'px' }">
        <!-- :style="{ height: customHeight - 60 + 'px', width: '100%' }" -->

        <l-tile-layer :url="url" :attribution="attribution" />
        <l-geo-json :geojson="geojson" :options="options" :options-style="styleFunction" />
      </l-map>
    </div>
    <div class="card_footer"></div>
  </div>
</template>


<script>
//@ts-ignore
import "leaflet/dist/leaflet.css";
import { latLng, featureGroup } from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import MapasService from "@/services/MapasService";
import { mapState } from "vuex";
import router from "@/router";
// import domtoimage from 'dom-to-image';
// import 'html2canvas'
// import 'leaflet.export'
import * as leafletImage from 'leaflet-image'
// import '@/plugins/leafleat_export'

export default {
  name: "cardMapa",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    dataParamsMap: {
      type: Object,
      default: null,
    },
    customHeight: {
      type: Number,
      default: 550,
    },
    zoomOptions: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    loading: false,
    show: true,
    enableTooltip: true,
    zoom: 10,
    // center: [48, -1.219482],
    geojson: null,
    //fillColor: "#e4ce7f",
    url: '',//"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "",
    marker: latLng(47.41322, -1.219482),
    paso: 0,
    layerfocus: null,

    imageMap: null
  }),

  computed: {
    ...mapState(["mapas", "menu"]),

    // showlMap() {
    //   return this.imageMap != null ? false : true
    // },  

    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      // const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        this.paso++;
        return {
          weight: 2,
          color: "black",
          opacity: 1,
          fillOpacity: 1,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return {}; //() => {};
      }
      return (feature, layer) => {
        // console.log("feature", feature);
        layer.options.fillColor =
          feature?.properties?.agrupacion?.color || "#808080";

        // feature.properties.INDRA_D ==
        //     this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
        //       ? "#ee3d8f"
        //       : feature?.properties?.agrupacion?.color != undefined ? 'black': "#808080",

        if (this.dataParamsMap?.idSeccion) {
          layer.setStyle({
            color:
              feature.properties.INDRA_D ==
                this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
                ? "#ee3d8f"
                : "black",
            weight:
              feature.properties.INDRA_D ==
                this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
                ? 10
                : 1,
          });

          if (
            feature.properties.INDRA_D ==
            this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
          ) {
            this.layerfocus = layer;
            this.$nextTick().then(() => {
              // console.log("nextick del seccion id");
              let groupseccion = new featureGroup();
              groupseccion.addLayer(layer);

              this.$refs.mapControl.mapObject.fitBounds(
                groupseccion.getBounds(),
                {
                  padding: [100, 100],
                }
              );
            });
          }
        }

      };
    },
  },
  async created() {
    this.loading = true;
    // console.log("dataParamsMap", this.dataParamsMap);
    if (this.dataParamsMap) {
      this.getMapas().then(() => {
        this.loading = false;
      });
    }
  },

  methods: {
    cutwithdots(text, caract) {
      let dots = "...";
      if (text.length > caract) {
        text = text.substring(0, caract) + dots;
      }
      return text;
    },

    // Función de exportación de imagen
    async exportMap() {


      // console.log(this.$refs.mapControl);
      // console.log(this.$refs.mapControl.);
      // console.log(await this.$refs.mapControl.mapObject.export());
      // this.$refs.mapControl.mapObject.export().then((r) => {
      //   this.imageMap = r.data
      // });


      // leafletImage(this.$refs.mapControl.mapObject, function (err, canvas) {
      //   // now you have canvas
      //   // example thing to do with that canvas:
      //   var img = this.$refs['pdf-map']
      //   console.log(img);
      //   var dimensions = this.$refs.mapControl.mapObject.getSize();
      //   img.width = dimensions.x;
      //   img.height = dimensions.y;
      //   img.src = canvas.toDataURL();
      //   document.getElementById('images').innerHTML = '';
      //   document.getElementById('images').appendChild(img);
      // });

      if (this.$refs.mapControl) {

        const map = this.$refs.mapControl.mapObject;

        leafletImage(map, (err, canvas) => {
          // now you have canvas
          // example thing to do with that canvas:
          var img = document.createElement('img');
          var dimensions = map.getSize();
          img.width = dimensions.x;
          img.height = dimensions.y;
          img.src = canvas.toDataURL();
          // console.log(img);
          this.imageMap = img.src
          // document.getElementById('img-map').innerHTML = '';
          // document.getElementById('img-map').appendChild(img);
          this.show = false;
          setTimeout(() => {
            this.$emit("loaded", this.index);
            // console.log("entro timeout");
          }, 1000)
          // console.log(canvas);
        });

      }


      // // Obtener el canvas del mapa
      // var canvas = document.getElementsByTagName('canvas');

      // console.log(canvas);
      // // Crear un objeto de imagen a partir del canvas
      // var image = new Image();
      // image.src = canvas.toDataURL('image/png');
      // // Crear un enlace de descarga y simular un clic en él
      // var link = document.createElement('a');
      // link.download = 'mapa.png';
      // link.href = image.src;

      // console.log(this.$refs.mapControl?.mapObject);
      // console.log(link);
      // link.click();

      // var mapElement = document.getElementById('mapControl');
      // console.log("🚀 ~ file: MapaInforme.vue:190 ~ exportMap ~ mapElement:", mapElement)

      // let width,height = this.customHeight;


      // const dataURL = await domtoimage.toPng(mapElement, { width,height })
      // console.log("🚀 ~ file: MapaInforme.vue:195 ~ exportMap ~ dataURL:", dataURL)


      // domtoimage.toPng(node)
      //   .then(function (dataUrl) {
      //     var img = new Image();
      //     img.src = dataUrl;
      //     document.body.appendChild(img);
      //   })
      //   .catch(function (error) {
      //     console.error('oops, something went wrong!', error);
      //   }).finally (() => {
      //     this.$emit("loaded", this.index);
      //   })


    },

    async getMapas() {
      if (this.dataParamsMap) {
        let dataMapa = await MapasService.getMapas({
          ...this.dataParamsMap,
          id_indra: this.dataParamsMap.idDistrito.toString().padStart(2, 0),
          tipo: "departamentos",
          minimizado: true,
        });
        // console.log("dataMapa", dataMapa.data);
        if (!dataMapa) {
          return;
        }

        // let mapaActual = dataMapa.data.find(mapa => mapa.ID_INDRA == this.dataParamsMap.idDistrito.toString().padStart(2, 0))
        this.geojson = dataMapa.data;

        if (Object.keys(this.geojson).length) {
          this.center = this.getCenterMap();

          this.$nextTick().then(() => {
            var group = new featureGroup();
            // console.log(this.dataParamsMap);
            this.$refs.mapControl?.mapObject.eachLayer(function (layer) {
              if (layer.feature != undefined) group.addLayer(layer);
            });

            // console.log("dataparams", this.dataParamsMap);
            this.$refs.mapControl?.mapObject.fitBounds(group.getBounds(), {
              padding: [20, 20],
            });

            this.geojson = dataMapa.data;

            let centro = this.getCenterMap()
            let zoom = this.$refs.mapControl?.mapObject.getZoom()

            setTimeout(() => {
              this.$refs.mapControl?.mapObject.setView(
                centro,
                zoom,
                {
                  animate: false,
                  pan: {
                    duration: 10,
                  },
                }
              );
              // this.$emit("loaded", this.index);
              this.exportMap()

            }, 3000)
          });
        }
      }
    },

    getCenterMap() {
      if (Object.keys(this.mapas).length) {
        let mapaContorno = this.mapas.find(
          (mapa) =>
            mapa.ID_INDRA ==
            this.dataParamsMap.idDistrito.toString().padStart(2, 0)
        );

        let allx = mapaContorno.features[0].geometry.coordinates[0].map(
          (x) => x[0]
        );
        let minx = Math.max.apply(null, allx);
        let maxx = Math.min.apply(null, allx);
        let centerx = (maxx - minx) / 2 + minx;

        let ally = mapaContorno.features[0].geometry.coordinates[0].map(
          (x) => x[1]
        );
        let miny = Math.max.apply(null, ally);
        let maxy = Math.min.apply(null, ally);
        let centery = (maxy - miny) / 2 + miny;
        // console.log("center", this.center);
        return [centery, centerx];
      } else {
        return [];
      }
    },
  },

  watch: {
    dataParamsMap() {
      if (!this.$route.params.norealoadMap) {
        this.loading = true;
        this.getMapas().then(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
.leaflet-container {
  background: rgb(255, 255, 255) !important;
  outline: 0;
}

// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(16px, -10px, 0px) !important ;
//   }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(10px, -10px, 0px) !important ;
//   }
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(-10px, -10px, 0px) !important ;
//   }
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(-20px, -10px, 0px) !important ;
//   }
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(-36px, -10px, 0px) !important ;
//   }
// }
// /* extraLarge devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 1600px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(-60px, -10px, 0px) !important ;
//   }
// }

// /* extraLarge devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 1800px) {
//   .leaflet-zoom-animated {
//     transform: translate3d(-110px, -10px, 0px) !important ;
//   }
// }// }
</style>