var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"link-unstyled",attrs:{"to":{
    name: 'Resultados',
    params: { ..._vm.data, distrito: _vm.distrito.IdDistrito },
  }}},[_c('div',{staticClass:"card cardprovincia"},[_c('div',{staticClass:"cardprovincia_header"},[_vm._v(" "+_vm._s(_vm.distrito.Distrito)+" ")]),_c('div',{staticClass:"cardprovincia_body text-center"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('l-map',{key:_vm.key,ref:"mapControl",staticStyle:{"height":"200px","width":"100%"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":{
              zoomControl: false,
              attributionControl: false,
              scrollWheelZoom: false,
              dragging:false
            }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),(_vm.show)?_c('l-geo-json',{attrs:{"geojson":_vm.geojson,"options-style":_vm.styleFunction}}):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"cardprovincia_footer"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }