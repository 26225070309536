<template>
  <v-card>
    <div class="d-flex flex-no-wrap justify-start" v-if="!this.$vuetify.breakpoint.mobile">
      <div>
        <v-card-title>
          <v-avatar class="blue lighten-4">
            <v-icon color="blue darken-4">mdi-file-document-outline</v-icon>
          </v-avatar>
        </v-card-title>
      </div>
      <div>
        <v-card-title class="text-h4 font-weight-bold">Generador de informes</v-card-title>

        <v-card-subtitle>Seleccione los filtros a aplicar y agruéguelos al informe mediante el
          botón "Sumar al informe". Podrá repetir esta acción las veces que
          desee y/o eliminar filtros ya agregados. Cuando haya finalizado la
          selección de las distintas secciones de datos de interés, podrá
          personalizar su informe presionando el botón “Diseñar informe". Por
          último descargue su documento personalizado en formato pdf presionando
          el botón "Generar el informe".</v-card-subtitle>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex justify-end pr-2 pt-2">
        <v-btn @click="setInformeModal(false)" icon color="red">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <v-row v-if="this.$vuetify.breakpoint.mobile">
      <v-col cols="11">
        <v-expansion-panels class="">
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate hide-actions>
              <v-card-title class="text-h5 font-weight-bold">
                Generador de informes

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" v-bind="attrs" v-on="on" class="ml-1">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Ayuda</span>
                </v-tooltip>
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Seleccione los filtros a aplicar y agruéguelos al informe mediante
              el botón "Sumar al informe". Podrá repetir esta acción las veces
              que desee y/o eliminar filtros ya agregados. Cuando haya
              finalizado la selección de las distintas secciones de datos de
              interés, podrá personalizar su informe presionando el botón
              “Diseñar informe". Por último descargue su documento personalizado
              en formato pdf presionando el botón "Generar el informe".
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="1">
        <div class="d-flex justify-end pr-2 pt-2 mt-6 mr-6">
          <v-btn @click="setInformeModalClose(false)" icon color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card-text>

      <ValidationObserver ref="informeForm">

        <v-row>
          <v-col cols="12" lg="4">
            <ValidationProvider name="periodo" :rules="'excluded:-1'" mode="eager" v-slot="{ errors }">
              <v-select :items="periodos" v-model="nuevoInforme.periodo" return-object filled outlined label="Año"
                :error-messages="errors">
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider name="eleccion" :rules="'validObject'" mode="eager" v-slot="{ errors }">
              <v-select :items="elecciones" v-model="nuevoInforme.eleccion" return-object filled outlined
                label="Elecciones" :error-messages="errors"></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider name="cargos" :rules="'validObject'" mode="eager" v-slot="{ errors }">
              <v-select :items="cargos" return-object v-model="nuevoInforme.cargo" filled outlined label="Cargo"
                :error-messages="errors"></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider name="distritos" :rules="'validObject'" mode="eager" v-slot="{ errors }">
              <v-select :items="distritos" return-object v-model="nuevoInforme.distrito" filled outlined label="Distrito"
                :error-messages="errors">
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col v-show="seccionesElectorales.length" cols="12" lg="4">
            <v-select :items="seccionesElectorales" filled v-model="nuevoInforme.seccionElectoral" return-object outlined
              label="Seccion electoral"></v-select>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select :items="secciones" return-object v-model="nuevoInforme.seccion" filled outlined label="Seccion ">
            </v-select>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select :items="agrupaciones" filled return-object item-text="nombre" item-value="idAgrupacion"
              v-model="nuevoInforme.agrupaciones" outlined multiple label="Agrupacion politica ">
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleAgrupaciones" @mousedown.prevent>
                  <v-list-item-action>
                    <v-icon :color="
                      nuevoInforme.agrupaciones.length > 0
                        ? 'indigo darken-4'
                        : ''
                    ">
                      {{ iconSelectAll }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Seleccionar todas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>

      </ValidationObserver>


      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="6" md="4">
          <v-btn block outlined color="primary" @click="cleanFilter()">
            Limpiar filtros
          </v-btn>
        </v-col>
        <v-col cols="6" md="4">
          <v-btn block color="primary" @click="agregarFiltroinforme()">
            Sumar al informe
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

    </v-card-text>
    <v-divider class="mx-4"></v-divider>

    <v-snackbar v-model="snackbar" :timeout="2500" top outlined color="primary">
      <div class="text-center">
        <h3>Se agrego filtro al informe</h3>
      </div>
    </v-snackbar>

    <template v-if="informes.length">
      <v-card-title>Filtros aplicados para su informe:</v-card-title>
      <v-list>
        <v-list-item v-for="(informe, index) in informes" :key="index">
          <v-list-item-icon>
            <v-btn fab width="18px" height="18px" elevation="0" color="grey darken-4"
              @click="deleteInformeFiltro(informe)">
              <v-icon x-small color="white"> mdi-close </v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              <b>Parte {{ index + 1 }}:</b> {{ informe.periodo }}>{{
                informe.eleccion.text
              }}>{{ informe.cargo.text }}>{{
  informe.distrito.text
}}{{
  informe.seccionElectoral && informe.seccionElectoral.value != -1
  ? ">" + informe.seccionElectoral.text
  : ""
}}
              {{
                informe.seccion && informe.seccion.value != -1
                ? ">" + informe.seccion.text
                : ""
              }}>Agrupaciones politicas:

              <template v-if="!informe.agrupaciones || informe.agrupaciones.length == 0">
                Todas
              </template>
              <template v-else>
                <template v-for="agrupaciones in informe.agrupaciones">
                  {{ agrupaciones.nombre }},
                </template>
              </template>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              Diseño Informe
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Incluir en el informe</v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item dense :key="i" v-for="(item, i) in options">
                <v-checkbox dense v-model="item.active" color="blue accent-4" :label="item.text"></v-checkbox>
                <!-- <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content> -->
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <!-- <v-subheader>REPORTS</v-subheader> -->
              <v-list-item-group v-model="selectedFormat" color="primary">
                <v-list-item v-for="(item, i) in formats" :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>

        <v-spacer></v-spacer>
        <v-btn @click="downloadReport()" :loading="loadReport" color="success">
          <v-icon small>mdi-file-download</v-icon>
          Generar informe
        </v-btn>
      </v-card-actions>



      <!-- <section style="width:780px">
        <Informe slot="pdf-content" v-if="dataparams.length" @loadedMapa="loadedMapa" :data="dataResult"
          :dataParams="dataparams" :options="options" :format="selectedFormat"></Informe>
      </section>   -->


      <vue-html2pdf :key="key" :show-layout="false" :float-layout="true" :enable-download="true"
        :preview-modal="false" :paginate-elements-by-height="1100" filename="Informe"
        :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="auto"
        ref="html2Pdf" @progress="onProgress($event)"> 
        <Informe id="pdf-content" slot="pdf-content" :key="key"  v-if="dataparams.length" @loadedMapa="loadedMapa" :data="dataResult"
        :dataParams="dataparams" :options="options" :format="selectedFormat" ></Informe>
      </vue-html2pdf>
      <!-- @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)" -->
      <!-- <section class="pdf-content">lalala</section> -->
      <!-- </section> -->
    </template>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import menuService from "@/services/MenuService";
import ResultadosService from "@/services/ResultadosService";
import store from "@/store";
// import VueHtml2pdf from "vue-html2pdf";
import VueHtml2pdf from '@/plugins/vue-html2pdf.vue';
// import Informe from "@/components/informe/Informe.vue";

const Informe = () => ({
  component: import("@/components/informe/Informe.vue"),
  // loading: LoadingComponent,
  // error: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  // timeout: 3000,
});

export default {
  name: "Informes",
  components: { VueHtml2pdf, Informe },
  data() {
    return {
      key:0,
      items: [],
      menu: [],
      snackbar: false,
      loadReport: false,

      selectedFormat: 1,
      formats: [
        { text: "Comparar", icon: "mdi-view-column" },
        { text: "Listas", icon: "mdi-view-stream" },
      ],

      options: [
        { value: 0, text: "Mapas", active: true },
        { value: 1, text: "Graficos", active: true },
        { value: 2, text: "Partidos", active: true },
      ],
      nuevoInforme: {
        periodo: -1,
        eleccion: {
          value: -1,
          text: "",
        },
        cargo: {
          value: -1,
          text: "",
        },
        distrito: {
          value: -1,
          text: "",
        },
        seccionElectoral: {
          value: -1,
          text: "",
        },
        seccion: {
          value: -1,
          text: "",
        },
        agrupaciones: [],
      },
      agrupaciones: [],

      showDesingInforme: false,

      loadedInforme: [],
      // {
      //   mapa: false,
      //   votos: false,
      // },
      //   informes: [
      //     {
      //       periodo: 2021,
      //       eleccion: "PASO",
      //       cargo: "Disputados provinciales",
      //       distrito: "Buenos Aires",
      //       seccionProvincial: "Seccion Capital",
      //       seccion: "La Plata",
      //     },
      //   ],
      dataResult: [],
      dataparams: [],
    };
  },
  computed: {
    ...mapState(["informes", "periodos", "newInforme", "informeModal"]),
    ...mapGetters(["getNewInforme"]),

    todasLasAgrupaciones() {
      return this.nuevoInforme.agrupaciones.length === this.agrupaciones.length;
    },
    algunasAgrupaciones() {
      return (
        this.nuevoInforme.agrupaciones.length > 0 && !this.todasLasAgrupaciones
      );
    },
    iconSelectAll() {
      if (this.todasLasAgrupaciones) return "mdi-close-box";
      if (this.algunasAgrupaciones) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    //retorno el listado de elecciones para el periodo (si existe)
    elecciones() {
      if (this.menu.length) {
        let elecciones = [];
        elecciones = this.menu.map((menu) => {
          return {
            value: menu.IdEleccion,
            text: menu.Elecciones,
          };
        });

        return elecciones;
      } else {
        return [];
      }
    },

    //retorno el listado de cargos para el periodo y eleccion
    cargos() {
      if (this.menu.length) {
        if (this.nuevoInforme.eleccion.value != -1) {
          let eleccion = {};
          let cargos;
          eleccion = this.menu.find(
            (menu) => menu.IdEleccion == this.nuevoInforme.eleccion.value
          );
          cargos = eleccion.Cargos.map((cargo) => {
            return {
              value: Number(cargo.IdCargo),
              text: cargo.Cargo,
            };
          });

          return cargos.sort((a, b) => a.value - b.value);
        }
        return [];
      } else {
        return [];
      }
    },

    //retora listado de secciones electorales con value y text

    distritos() {
      if (
        this.cargos.length &&
        this.nuevoInforme.cargo.value &&
        this.nuevoInforme.eleccion.value != -1
      ) {
        let cargo = this.menu
          .find((m) => m.IdEleccion == this.nuevoInforme.eleccion.value)
          ?.Cargos.find((c) => c.IdCargo == this.nuevoInforme.cargo.value);
        return cargo
          ? cargo.Distritos.map((d) => {
            return {
              value: d.IdDistrito,
              text: d.Distrito,
            };
          })
          : [];
      } else {
        return [];
      }
    },

    //retora listado de secciones electorales con value y text
    seccionesElectorales() {
      if (
        this.cargos.length &&
        this.nuevoInforme.cargo.value &&
        this.nuevoInforme.eleccion.value != -1 &&
        this.nuevoInforme.distrito.value != -1
      ) {
        let distrito = this.menu
          .find((m) => m.IdEleccion == this.nuevoInforme.eleccion.value)
          ?.Cargos.find((c) => c.IdCargo == this.nuevoInforme.cargo.value)
          .Distritos.find(
            (d) => d.IdDistrito == this.nuevoInforme.distrito.value
          );
        // return seccionesElectorales ? seccionesElectorales.
        return distrito
          ? distrito.SeccionesProvinciales.filter((sp) => {
            return sp.IDSeccionProvincial;
          })
            .map((sp) => {
              return {
                value: sp.IDSeccionProvincial,
                text: sp.SeccionProvincial,
              };
            })
            .sort(this.sortSelectValue)
          : [];
      }
      return [];
    },

    secciones() {
      if (
        this.distritos.length &&
        this.nuevoInforme.distrito.value != 0 &&
        this.nuevoInforme.distrito.value != -1
      ) {
        let secProvinciales = this.menu
          .find((m) => m.IdEleccion == this.nuevoInforme.eleccion.value)
          ?.Cargos.find((c) => c.IdCargo == this.nuevoInforme.cargo.value)
          .Distritos.find(
            (d) => d.IdDistrito == this.nuevoInforme.distrito.value
          ).SeccionesProvinciales;
        if (secProvinciales && secProvinciales.length > 1) {
          if (this.nuevoInforme.seccionElectoral.value != -1) {
            return secProvinciales
              .find(
                (sp) =>
                  sp.IDSeccionProvincial ==
                  this.nuevoInforme.seccionElectoral.value
              )
              .Secciones.map((s) => {
                return {
                  value: s.IdSeccion,
                  text: s.Seccion,
                };
              })
              .sort(this.sortSelect);
          }
        } else {
          return secProvinciales[0].Secciones.map((s) => {
            return {
              value: s.IdSeccion,
              text: s.Seccion,
            };
          }).sort(this.sortSelect);
        }
      }

      return [];
    },
  },
  methods: {


    // hasGenerated(event) {
    //   console.log("entro event");
    //   console.log(event);
    //   this.key ++;
    // },  

    loadedMapa(index) {
      // this.$refs.html2Pdf.generatePdf();

      this.loadedInforme[index].mapa = true;

      this.loadedVotos(index);
      // console.log('se termino de cargar mapa en informes');
    },

    loadedVotos(index) {
      // this.$refs.html2Pdf.generatePdf();
      this.loadedInforme[index].votos = true;
      // console.log('se termino de cargar mapa en informes');
    },

    onProgress($event) {
      console.log($event);
    },

    ...mapActions(["setInformeModal", "setInformes", "setNewInforme"]),


    setInformeModalClose(value) {
      this.setNewInforme({})
      console.log(value);
      this.setInformeModal(value)
    },

    toggleAgrupaciones() {
      this.$nextTick(() => {
        if (this.todasLasAgrupaciones) {
          this.nuevoInforme.agrupaciones = [];
        } else {
          this.nuevoInforme.agrupaciones = this.agrupaciones.slice();
        }
      });
    },

    deleteInformeFiltro(informe) {
      const index = this.informes.indexOf(informe);
      if (index > -1) {
        this.informes.splice(index, 1);
        // console.log(auxinformes, informe,index);
      }

      this.setInformes(this.informes);

      return true;
    },

    getMenu: async (data) => {
      try {
        let dataFiltros = await menuService.get(data);
        store.commit("setMenu", dataFiltros.data);
        return dataFiltros.data;
        // console.log(menu);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async validate() {
      let result = await this.$refs.informeForm.validate();
      return result;
    },

    async agregarFiltroinforme() {

      let valid = await this.validate();

      console.log(valid);

      if (valid) {

        if (
          this.nuevoInforme.periodo != -1 &&
          this.nuevoInforme.eleccion.value != -1 &&
          this.nuevoInforme.cargo.value != -1 &&
          this.nuevoInforme.distrito.value != -1
        ) {

          if (this.nuevoInforme.eleccion.text == "") {
            this.nuevoInforme.eleccion = this.elecciones.find((e) => e.value == this.nuevoInforme.eleccion.value)
          }

          if (this.nuevoInforme.cargo.text == "") {
            this.nuevoInforme.cargo = this.cargos.find((e) => e.value == this.nuevoInforme.cargo.value)
          }

          if (this.nuevoInforme.distrito.text == "") {
            this.nuevoInforme.distrito = this.distritos.find((e) => e.value == this.nuevoInforme.distrito.value)
          }

          if (this.nuevoInforme.seccionElectoral.value != -1 && this.nuevoInforme.seccionElectoral.text == "") {
            this.nuevoInforme.seccionElectoral = this.seccionesElectorales.find((e) => e.value == this.nuevoInforme.seccionElectoral.value)
          }

          if (this.nuevoInforme.seccion.value != -1 && this.nuevoInforme.seccion.text == "") {
            this.nuevoInforme.seccion = this.secciones.find((e) => e.value == this.nuevoInforme.seccion.value)
          }



          let informesaux = this.informes.slice();
          if (
            this.nuevoInforme.agrupaciones.length &&
            this.todasLasAgrupaciones
          ) {
            this.nuevoInforme.agrupaciones = [];
          }

          this.snackbar = true
          informesaux.push({ ...this.nuevoInforme });

          this.setInformes(informesaux.slice());
        }

      }

    },

    getResultados: async (data) => {
      try {
        let dataFiltros = await ResultadosService.getTotalizados(data);

        return dataFiltros.data;
        // console.log(menu);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    cleanFilter() {
      let initilize = {
        value: -1,
        text: "",
      };
      this.nuevoInforme.periodo = initilize;
      this.nuevoInforme.eleccion = initilize;
      this.nuevoInforme.cargo = initilize;
      this.nuevoInforme.distrito = initilize;
      this.nuevoInforme.seccionElectoral = initilize;
      this.nuevoInforme.seccion = initilize;
      return true;
    },

    async downloadReport() {
      // this.key ++;
      this.loadReport = true;
      try {

        if (this.informes.length) {
          this.dataparams = [];

          this.informes.map((informeData) => {
            let informeParam = {
              año: informeData.periodo,
              recuento: "Provisorio",
              idEleccion: informeData.eleccion.value,
              idCargo: informeData.cargo.value,
              idDistrito: informeData.distrito.value,
              agrupaciones: informeData.agrupaciones,
            };
            if (informeData.seccionElectoral.value != -1) {
              informeParam.idSeccionProvincial = informeData.seccionElectoral.value;
            }
            if (informeData.seccion.value != -1) {
              informeParam.idSeccion = informeData.seccion.value;
            }

            this.dataparams.push(informeParam);
          });


          let allResultados = await Promise.all(
            this.dataparams.map(async (dp) => {
              let resultAux = await ResultadosService.getTotalizados(dp);

              if (dp.agrupaciones && dp.agrupaciones.length) {
                resultAux.data.agrupaciones =
                  resultAux.data.agrupaciones.filter((a) =>
                    dp.agrupaciones
                      .map((agrupacionAux) => agrupacionAux.idAgrupacion)
                      .includes(a.idAgrupacion)
                  );
              }

              return resultAux.data;
            })
          );

          allResultados.forEach((res) => {
            this.loadedInforme.push({
              mapa: false,
              votos: false,
            });
          });

          // let auxdata = await ResultadosService.getTotalizados(this.dataparams);
          this.dataResult = allResultados;
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
      return true;
    },
  },


  // created() {
  //   console.log('Creo informes');
  // },


  watch: {

    "informeModal": {
      handler(act, prev) {
        if (act == false) {
          this.nuevoInforme = {
            periodo: -1,
            eleccion: {
              value: -1,
              text: "",
            },
            cargo: {
              value: -1,
              text: "",
            },
            distrito: {
              value: -1,
              text: "",
            },
            seccionElectoral: {
              value: -1,
              text: "",
            },
            seccion: {
              value: -1,
              text: "",
            },
            agrupaciones: [],
          }
        }


      },
      deep: true,
      immediate: true
    },

    "getNewInforme.año": {
      handler(act, prev) {
        if (act) {
          this.nuevoInforme.periodo = parseInt(act)
          delete this.newInforme.año
        }


      },
      deep: true,
      immediate: true
    },

    async "nuevoInforme.periodo"() {
      this.menu = await this.getMenu({
        año: this.nuevoInforme.periodo,
      });


      if (this.newInforme.idEleccion) {
        this.nuevoInforme.eleccion.value = parseInt(this.newInforme.idEleccion);
        delete this.newInforme.idEleccion
      } else {

        this.nuevoInforme.eleccion = {
          value: -1,
          text: "",
        };
      }


    },

    "nuevoInforme.eleccion.value"() {

      if (this.newInforme.idCargo) {
        this.nuevoInforme.cargo.value = parseInt(this.newInforme.idCargo);
        delete this.newInforme.idCargo
      } else {

        this.nuevoInforme.cargo = {
          value: -1,
          text: "",
        };
      }



    },
    "nuevoInforme.cargo.value"(act, prev) {
      if (act == 1 || act == 8) {
        this.nuevoInforme.distrito = {
          value: 0,
          text: "",
        };
      } else if (this.newInforme.idDistrito) {
        this.nuevoInforme.distrito.value = parseInt(this.newInforme.idDistrito);
        delete this.newInforme.idDistrito
      } else {

        this.nuevoInforme.distrito = {
          value: -1,
          text: "",
        };
      }

      this.nuevoInforme.agrupaciones = [];
    },

    "nuevoInforme.distrito.value"(act, prev) {
      if (this.newInforme.idSeccionProvincial) {
        this.nuevoInforme.seccionElectoral.value = parseInt(this.newInforme.idSeccionProvincial);
        delete this.newInforme.idSeccionProvincial
      } else {

        this.nuevoInforme.seccionElectoral = {
          value: -1,
          text: "",
        };
      }

      if (this.newInforme.idSeccion) {
        this.nuevoInforme.seccion.value = parseInt(this.newInforme.idSeccion);
      } else {

        this.nuevoInforme.seccion = {
          value: -1,
          text: "",
        };
      }


      if (act != -1) {
        this.agrupaciones = [
          {
            disabled: true,
            idAgrupacion: -1,
            nombre: "Obteniendo agrupaciones...",
          },
        ];

        // console.log('this.nuevoInforme.periodo.value: ',this.nuevoInforme.periodo);

        const data = {
          año: this.nuevoInforme.periodo,
          idCargo: this.nuevoInforme.cargo.value,
          idDistrito: this.nuevoInforme.distrito.value,
          idEleccion: this.nuevoInforme.eleccion.value,
          recuento: "Provisorio",
        };

        this.nuevoInforme.agrupaciones = [];
        this.getResultados(data).then((data) => {
          this.agrupaciones = data.agrupaciones;
          // console.log(data);
        });
      }
    },

    "nuevoInforme.seccionElectoral.value"(act, prev) {
      if (this.newInforme.idSeccion) {
        this.nuevoInforme.seccion.value = parseInt(this.newInforme.idSeccion);
        delete this.newInforme.idSeccion
      } else {

        this.nuevoInforme.seccion = {
          value: -1,
          text: "",
        };
      }

      if (act != -1) {
        this.agrupaciones = [
          {
            disabled: true,
            idAgrupacion: -1,
            nombre: "Obteniendo agrupaciones...",
          },
        ];

        const data = {
          año: this.nuevoInforme.periodo,
          idCargo: this.nuevoInforme.cargo.value,
          idDistrito: this.nuevoInforme.distrito.value,
          idEleccion: this.nuevoInforme.eleccion.value,
          idSeccionProvincial: this.nuevoInforme.seccionElectoral.value,
          recuento: "Provisorio",
        };

        this.getResultados(data).then((data) => {
          this.agrupaciones = data.agrupaciones;
          // console.log(data);
        });
      }

      this.nuevoInforme.agrupaciones = [];
    },

    "nuevoInforme.seccion.value"() {
      if (
        (this.nuevoInforme.periodo != -1,
          this.nuevoInforme.eleccion.value != -1,
          this.nuevoInforme.cargo.value != -1,
          this.nuevoInforme.distrito.value != -1)
      ) {
        this.agrupaciones = [
          {
            disabled: true,
            idAgrupacion: -1,
            nombre: "Obteniendo agrupaciones...",
          },
        ];

        const data = {
          año: this.nuevoInforme.periodo,
          idCargo: this.nuevoInforme.cargo.value,
          idDistrito: this.nuevoInforme.distrito.value,
          idEleccion: this.nuevoInforme.eleccion.value,
          recuento: "Provisorio",
        };

        if (this.nuevoInforme.seccionElectoral.value != -1) {
          data.idSeccionProvincial = this.nuevoInforme.seccionElectoral.value;
        }
        if (this.nuevoInforme.seccion.value != -1) {
          data.idSeccion = this.nuevoInforme.seccion.value;

          this.nuevoInforme.agrupaciones = [];
        }
        this.getResultados(data).then((data) => {
          this.agrupaciones = data.agrupaciones;
          // console.log(data);
        });
      }
    },

    // watcheo de array loadedinforme que valide q todos los mapas y votos esten cargados para dps renderizar el informe
    loadedInforme: {
      handler() {
        if (this.loadedInforme.length > 0) {
          if (
            !this.loadedInforme.find((li) => {
              return li.votos == false || li.mapa == false;
            })
          ) {
            this.loadedInforme = [];
            this.loadReport = false;
            // let data64 = btoa(JSON.stringify ({
            //   data:this.dataResult,
            //   dataParams: this.dataparams,
            //   options: this.options, 
            //   format: this.selectedFormat
            // }))
            // let route = this.$router.resolve({ name: 'informes', params: {
            //   data:this.dataResult,
            //   dataParams: this.dataparams,
            //   options: this.options, 
            //   format: this.selectedFormat
            // } })
            // console.log(window.location.origin + route.href);
            // window.open(route.href, '_blank');
            this.$refs.html2Pdf.generatePdf();  
            // setTimeout(() => {
            //   // this.$refs.html2Pdf.$destroy()
            // },10000)
          }
        }
      },
      deep: true,
    },
  },
};
</script>


.v-expansion-panel::before {
  box-shadow: none !important;
}
}
</style>