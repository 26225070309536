<template>
  <v-container fluid class="primary pa-0 ma-0">
    <v-container>
      <v-row>
        <v-col cols="6" md="4" lg="3" :class="{ 'd-flex child-flex': !$vuetify.breakpoint.mobile }">
          <router-link to="/">
            <v-img max-height="35" contain aspect-ratio="1" src="@/assets/images/Logo_DINE_negativo.png"></v-img>
          </router-link>
          <v-spacer></v-spacer>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" class="d-none d-lg-block align-self-center">
          <v-row justify="end">
            <v-col cols="4">
              <router-link :to=" this.$route.name !== 'Desarrollo' ? '' : '/' ">
                <div :class="{
                  'white--text text-center text-md-right mr-5': true,
                  'font-weight-bold': this.$route.name !== 'Desarrollo'
                }" style="font-size: 1.3rem">
                  Resultados
                </div>
              </router-link>
            </v-col>
            <v-col cols="4">
              <router-link to="/desarrollo">
                <div :class="{
                  'white--text text-center text-md-right ': true,
                  'font-weight-bold': this.$route.name == 'Desarrollo'
                }" style="font-size: 1.3rem">
                  Desarrolladores/as
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="d-lg-none d-flex justify-end align-self-end text-right">
          <v-icon class="mr-5" @click="redirect" color="white" size="30">mdi-magnify</v-icon>
          <v-img max-height="30" max-width="30" @click="redirectFromDevelop" src="@/assets/images/api.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>

  <!-- <v-app-bar app color="#18A0FB">
    <div class="d-flex justify-content-betwean">

    </div>
    <v-container>
      <v-row>
        <v-col class="ma-2">
          <v-img src="@/assets/images/Logo_DINE_negativo.png"></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>

        </v-col>
      </v-row>
    </v-container>
  </v-app-bar> -->
</template>

<script >
import Vue from "vue";
import { mapState } from "vuex";
import store from "@/store";
import router from "@/router";

export default {
  name: "Header",

  computed: {

    modalfilter: {
      get() {
        return this.$store.state.modalfilter;
      },
      set(value) {
        this.$store.commit("setModalfilter", value);
      },
    },
  },
  methods: {
    redirect() {
      if (this.$route.name == 'Desarrollo') {
        router.push('/');
      }
      this.modalfilter = true
    },
    redirectFromDevelop() {
      if (this.$route.name !== 'Desarrollo') {
        router.push('/desarrollo')
      }
    }
  }

};


</script>

<style></style>