<template>
  <v-container class="pt-0 px-0">
    <v-tabs v-model="tabselect" centered color="dark" class="custom-tab">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab 
        :v-if="cargos"
        v-for="cargo in cargos"
        :key="cargo.IdCargo"
        :to=" String(cargo.IdCargo)"
        >{{ cargo.Cargo }}</v-tab
      >
      <!-- :to="{ name: 'Categoria', params: { periodo, eleccion, cargo, menu } }" -->
      <!-- <v-tab :key="4" >Categoria</v-tab>
      <v-tab :key="5" >Categoria 2</v-tab>
      <v-tab :key="6" >Categoria</v-tab>
      <v-tab :key="7" >Categoria</v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="tabselect" class="background">
        <Categoria></Categoria>
      <!-- <v-tab-item v-for="cargo in cargos" :key="cargo.IdCargo" :value="String(cargo.IdCargo) ">
      </v-tab-item> -->
      <!-- <router-view></router-view> -->
    </v-tabs-items>
  </v-container>
</template>

<script >
import router from "@/router";
// import Vue from "vue";
// import Card from "@/components/Card.vue";
import Categoria from "@/components/Categoria.vue";

import menuService from "@/services/MenuService";

export default {
  name: "Categorias",

  components: { Categoria },

  data: () => ({
    tabselect: 2,
    menu: [],
    periodo: null,
    eleccion: null,
    cargo: null,
  }),

  created() {
    // console.log('lalala',this.periodo, this.eleccion, this.cargo);
    // console.log(this.cargos);

    this.initData();

    // this.$watch (() => this.$route.params,this.initData())
  },

  computed: {


    cargos() {
      if (this.menu && this.menu.length && this.eleccion) {
        let eleccion = {};
        eleccion = this.menu.find((menu) => menu.IdEleccion == this.eleccion);

        return eleccion.Cargos.sort( (a,b) => a.IdCargo - b.IdCargo );
      } else {
        return [];
      }
    },
  },
  methods: {
    initData() {
      
      this.periodo = this.$route.params.periodo;
      this.eleccion = this.$route.params.eleccion;
      this.cargo = this.$route.params.cargo;


      this.getMenu({
        año: this.periodo,
      }).then((data) => {
        this.menu = data;
        // this.directToCategory();
        // console.log('tab' +this.cargo);
        this.tabselect = String(this.cargo);
      });
    },

    // directToCategory() {
    //   router.push({
    //     name: "Categoria",
    //     params: {
    //       periodo: this.periodo,
    //       eleccion: this.eleccion,
    //       cargo: this.cargo,
    //       menu: this.menu,
    //     },
    //   });
    // },

    getMenu: async (data) => {
      try {
        let dataFiltros = await menuService.get(data);

        return dataFiltros.data;
        // console.log(menu);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },

  // watch: {
  //   "$route.params": {
  //     handler: function (search) {
  //       this.initData();
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
};
</script>

<style>
.custom-tab {
  font-weight: bold;
}
</style>