<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="7" class="py-0">
        <p class="text-left mb-0">
          {{ partido.lista || partido.Agrupacion }}
        </p>
      </v-col>
      <v-col cols="12" md="5" class="py-0 text-right">
        <span class="text-subtitle-2 pr-2">
          {{ porcentVotosPartido(partido, partidos) }}%
          </span>
        <span class="text-subtitle-2">{{ partido.Votos }} votos</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-progress-linear
          :color="color"
          class="my-0"
          :value="porcentVotosPartido(partido, partidos)"
          height="10"
          rounded
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "partidoProgress",
  props: {
    color:String,
    partido: {
      type: Object,
      default() {
        return {};
      },
    },

    partidos: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  methods: {
    porcentVotosPartido(partido, partidos) {
      let totalVotosPartido = partidos.reduce( (sum, value) => sum + value.Votos,0);
      return ((partido.Votos * 100) / totalVotosPartido).toFixed(2);
    },
  },
  
};
</script>

<style>
</style>