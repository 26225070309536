var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card cardmapa",style:({ height: _vm.customHeight + 'px' })},[_c('div',{staticClass:"card_title",staticStyle:{"background":"transparent"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-img',{attrs:{"id":"imgMap","src":_vm.imageMap}})],1),_c('div',{staticClass:"card_body mapaInforme"},[(_vm.show)?_c('l-map',{ref:"mapControl",style:({ height: _vm.customHeight - 60 + 'px', width: _vm.customHeight - 60 + 'px' }),attrs:{"id":"mapControl","zoom":_vm.zoom,"options":{
      zoomControl: _vm.zoomOptions,
      attributionControl: false,
      scrollWheelZoom: false,
      preferCanvas: true,
      dragging: !_vm.$vuetify.breakpoint.mobile,
    }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-geo-json',{attrs:{"geojson":_vm.geojson,"options":_vm.options,"options-style":_vm.styleFunction}})],1):_vm._e()],1),_c('div',{staticClass:"card_footer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }