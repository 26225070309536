<template>
    <v-app class="background">
        <Header />

        <v-main class="main_pb">
            <v-container fluid>
                <v-row class="dark" v-show="!$route.params.periodo">
                    <v-container class="pb-md-10">
                        <v-row justify="start">
                            <v-col cols="12" md="4" class="pl-5 pl-md-0  pt-10 pb-10 order-1 order-md-0">
                                <div class="white--text pr-md-5 py-md-5 py-2 font-weight-bold titulo">
                                    Información para desarrollo de aplicaciones y análisis de datos
                                </div>
                                <div class="white--text py-md-2 py-2 text-subtitle-1">
                                    La aplicación Publicación de Resultados Electorales provee funcionalidades para
                                    que pueda ser utilizada por desarrolladores y desarrolladoras de software. 
                                </div>
                            </v-col>

                            <v-col cols="12" md="7" class="pb-0 order-0 order-md-1 mt-6">
                                <!-- max-height="50" -->
                                <v-img class="ml-auto" max-height="275px" src="@/assets/images/desarrollo.png"></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
                <!-- Body -->
                <v-row class="white">
                    <v-container>
                        <v-row>
                            <v-col offset="1" md="3" class="pl-5 pl-md-0 pt-10 pb-10 d-md-block d-none order-md-0">
                                <div class="dark--text pr-md-5 py-md-5 py-2 font-weight-bold titulo">Introducción</div>
                                <div class="dark--text pr-md-5 py-md-5 py-2 text-subtitle-1">
                                    <a href="#api" class="link-text">API de consulta de resultados</a>
                                </div>
                                <div class="dark--text pr-md-5 py-md-5 py-2 text-subtitle-1">
                                    <a href="#csv" class="link-text">Exportación de datos en formato CSV</a>
                                </div>
                            </v-col>

                            <v-col cols="11" md="7" class="pb-0 order-0 order-md-1 mt-6 extra-class">
                                <div class="dark--text py-md-2 py-2 text-subtitle-2">
                                    La Dirección Nacional Electoral y el Observatorio Político Electoral del Ministerio del Interior ponen a disposición dos métodos de consulta dentro de la aplicación de Publicación de Resultados Electorales para que puedan ser utilizados tanto por desarrolladoras y desarrolladores de aplicaciones como por analistas de datos. Estos métodos son una API de consulta y la exportación de datos en formato CSV, los cuales se describen a continuación.
                                </div>
                                <div class="dark--text pr-md-5 py-md-5 py-2 titulo" id="api">
                                    API de consulta de resultados
                                </div>
                                <div class="dark--text py-md-2 py-2 text-subtitle-2">
                                    La aplicación Publicación de Resultados provee una API compatible con <a href="https://es.wikipedia.org/wiki/Especificaci%C3%B3n_OpenAPI" target="_blank">OpenAPI v3</a> que puede ser consultada para obtener los datos electorales que se encuentran disponibles de manera programática.<br>
                                    <br>
                                    La documentación técnica de la API puede obtenerse <a :href="apiUrl" target="_blank" >aquí</a> para que pueda usarse en un editor de API (Postman, Insomnia, Swagger, etc).<br>
                                    <br>
                                    Adicionalmente, se ofrece una <a :href="insomniaUrl" target="_blank">colección de Insomnia</a> con la especificación de la API y algunos ejemplos de consultas. En el video a continuación puede verse cómo importar, seleccionar y ejecutar consultas.
                                </div>
                                <div class="mt-4">
                                    <iframe width="100%" :src="video" height="480px" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div class="dark--text py-md-2 py-2 text-subtitle-2">
                                    <br><br>
                                    Ejemplo de consulta a la API desde línea de comandos con cURL:
                                    <br><br>
                                    curl -X 'GET' 'https://resultados.mininterior.gob.ar/api/resultados/getResultados?anioEleccion=2019&tipoRecuento=1&tipoEleccion=1&categoriaId=2&distritoId=1&seccionProvincialId=0&seccionId=3&circuitoId=000039&mesaId=1244' -H 'accept: application/json'                   <br><br>
                                    <pre class="microlight" style="display: block; overflow-x: auto; padding: 0.5em; max-height: 480px;background: rgb(33, 33, 33); color: white;"><code class="language-json" style="white-space: pre;">
                                        <span>{
</span><span>  </span><span class="hljs-attr">"fechaTotalizacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"2023-04-27T01:24:24.825Z"</span><span>,
</span><span>  </span><span class="hljs-attr">"estadoRecuento"</span><span>: {
</span><span>    </span><span class="hljs-attr">"mesasEsperadas"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>    </span><span class="hljs-attr">"mesasTotalizadas"</span><span>: </span><span style="color: rgb(211, 99, 99);">1</span><span>,
</span><span>    </span><span class="hljs-attr">"mesasTotalizadasPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>    </span><span class="hljs-attr">"cantidadElectores"</span><span>: </span><span style="color: rgb(211, 99, 99);">347</span><span>,
</span><span>    </span><span class="hljs-attr">"cantidadVotantes"</span><span>: </span><span style="color: rgb(211, 99, 99);">255</span><span>,
</span><span>    </span><span class="hljs-attr">"participacionPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">73.49</span><span>
</span>  },
<span>  </span><span class="hljs-attr">"valoresTotalizadosPositivos"</span><span>: [
</span>    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-502"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"FRENTE DE TODOS"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">90</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">35.29</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"CELESTE Y BLANCA"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">90</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"87"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"UNITE POR LA LIBERTAD Y LA DIGNIDAD"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">13</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">5.1</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"OLA CELESTE"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">13</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-501"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">13</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">5.1</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"UNIDAD"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">13</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-504"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"CONSENSO FEDERAL"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">17</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">6.67</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"CONSENSO PARA EL FUTURO"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">17</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-262"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"EL MOVIMIENTO"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"CELESTE"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-503"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"JUNTOS POR EL CAMBIO"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">96</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">37.65</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"JUNTOS SOMOS EL CAMBIO"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">96</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"13"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"MOVIMIENTO AL SOCIALISMO"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">2</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0.78</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"UNIDAD DE LA IZQUIERDA"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">2</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"5"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"DEMOCRATA CRISTIANO"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">3</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">1.18</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"CIUDAD CELESTE POR LA VIDA Y LA FAMILIA"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">3</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"86"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"FRENTE RENOVADOR AUTENTICO"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"NUEVA CORRIENTE"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"88"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"PARTIDO DIGNIDAD POPULAR"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"LIBERACION"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"1A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-187"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"AUTODETERMINACION Y LIBERTAD"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">4</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">1.57</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"QUE LOS PUEBLOS MANDEN"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">4</span><span>
</span>        }
      ]
    },
    {
<span>      </span><span class="hljs-attr">"idAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"01-343"</span><span>,
</span><span>      </span><span class="hljs-attr">"nombreAgrupacion"</span><span>: </span><span style="color: rgb(162, 252, 162);">"MOVIMIENTO DE JUBILADOS Y JUVENTUD"</span><span>,
</span><span>      </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"votosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>      </span><span class="hljs-attr">"idAgrupacionTelegrama"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"urlLogo"</span><span>: </span><span style="color: rgb(162, 252, 162);">""</span><span>,
</span><span>      </span><span class="hljs-attr">"listas"</span><span>: [
</span>        {
<span>          </span><span class="hljs-attr">"nombre"</span><span>: </span><span style="color: rgb(162, 252, 162);">"MISION CELESTE"</span><span>,
</span><span>          </span><span class="hljs-attr">"numero"</span><span>: </span><span style="color: rgb(162, 252, 162);">"A"</span><span>,
</span><span>          </span><span class="hljs-attr">"votos"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>
</span>        }
      ]
    }
  ],
<span>  </span><span class="hljs-attr">"valoresTotalizadosOtros"</span><span>: {
</span><span>    </span><span class="hljs-attr">"votosNulos"</span><span>: </span><span style="color: rgb(211, 99, 99);">2</span><span>,
</span><span>    </span><span class="hljs-attr">"votosNulosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0.78</span><span>,
</span><span>    </span><span class="hljs-attr">"votosEnBlanco"</span><span>: </span><span style="color: rgb(211, 99, 99);">15</span><span>,
</span><span>    </span><span class="hljs-attr">"votosEnBlancoPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">5.88</span><span>,
</span><span>    </span><span class="hljs-attr">"votosRecurridosComandoImpugnados"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>,
</span><span>    </span><span class="hljs-attr">"votosRecurridosComandoImpugnadosPorcentaje"</span><span>: </span><span style="color: rgb(211, 99, 99);">0</span><span>
</span>  }
}</code></pre>
                                </div>
                                <div class="dark--text pr-md-5 py-md-5 py-2 titulo mt-3" id="csv">
                                    Exportación de datos en formato CSV
                                </div>
                                <div class="dark--text py-md-2 py-2 text-subtitle-2">
                                    En cada página de consulta de resultados electorales es posible exportar los datos en formato CSV para que pueda ser explotado con otras herramientas de análisis de datos. El archivo CSV generado incluye la información de todas las mesas del ámbito electoral de la consulta que se está visualizando, con lo cual, el mismo puede ser extenso.
                                    <br>
                                    <br>
                                    El archivo CSV generado se encuentra formateado de acuerdo al Estándar de Preservación de Datos Electorales, el cual puede consultarse <a :href="estandardatos" target="_blank" >aquí</a>. Este documento provee información sobre los distintos campos y las convenciones de uso que aplican a los datos exportados en formato CSV.
                                </div>
                                <div>
                                    <v-img class="mt-4" contain src="@/assets/images/csv.svg"></v-img>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
            </v-container>
        </v-main>

        <Footer></Footer>
    </v-app>
</template>
  
<script>
import Header from "../dashboard/Header.vue";
import Footer from "../dashboard/Footer.vue";

export default {
    components: { Header, Footer },
    data: () => ({
        apiUrl: process.env.VUE_APP_API_URL,
        insomniaUrl: process.env.VUE_APP_INSOMNIA_COLLECTION,
        video: process.env.VUE_APP_VIDEO,
        estandardatos: process.env.VUE_APP_ESTANDAR_DATOS
    })
};
</script>
  
<style>
.main_pb {
    padding-bottom: 5rem !important;
    background-color: #FFFFFF !important;
}

.titulo {
    font-size: 1.8rem !important;
}
.extra-class {
    /* overflow-y: scroll;
    max-height: 100vh !important; */
}
.link-text {
    color: black !important;
    text-decoration:none;
}
.no-underline {
    text-decoration: none;
}
</style>
