<template>
  <v-app style="max-width: 790px;">
    <v-main class="informe" v-if="data.length">
      <template v-for="(informe, index) in data">
        <v-container :key="index">
          <!-- {{ index }} -->
          <v-divider v-if="index != 0 && (format == 1 || index % 2 == 0)" class="my-6"></v-divider>
          <div v-if="format == 1 || index % 2 == 0" class="d-flex justify-content-between pb-0 mb-0">
            <v-img max-height="60" width="60" height="60" contain eager position="start" aspect-ratio="1"
              src="@/assets/images/logo-mini-negro.svg"></v-img>
            <v-img max-height="60" height="60" width="60" contain eager position="center" aspect-ratio="1"
              src="@/assets/images/Logotipo_DINE_negro-svg.svg"></v-img>
            <v-img max-height="60" width="60" height="60" contain eager position="right" aspect-ratio="1"
              src="@/assets/images/logos_001-8.png"></v-img>

          </div>

          <template v-if="format == 1">
            <v-row>
              <v-col cols="12">
                <div class="font-weight-bold text-h4">
                  <span class="span_title" v-for="(title, index) in titles(informe)" :key="index">
                    {{ title }}
                  </span>
                </div>

                <div class="text-subtitle-1">
                  <v-breadcrumbs class="ml-0 pl-0 pb-0 pt-1 text-capitalize" divider="/" :items="subtitles(informe)">
                  </v-breadcrumbs>
                </div>
              </v-col>
              <v-col v-if="format == 1" lg="12" class="pt-2">
                <v-row>
                  <v-col class="pt-1">
                    <Infocard :loading="loading" title="Mesas computadas" :description="
                      Number(informe.MesasEscrutadas).toLocaleString()
                    ">
                      <template v-slot:icon>
                        <v-icon size="50">$vuetify.icons.votar</v-icon>
                      </template>
                    </Infocard>
                  </v-col>
                  <v-col class="pt-1">
                    <Infocard :loading="loading" title="Electores" color="magenta"
                      :description="Number(informe.Electores).toLocaleString()">
                      <template v-slot:icon>
                        <v-icon size="50">$vuetify.icons.comunidad</v-icon>
                      </template>
                    </Infocard>
                  </v-col>
                  <v-col class="pt-1">
                    <Infocard :loading="loading" smallFont title="Participación sobre escrutado" color="lilac"
                      :description="
                        Number(
                          informe.ParticipacionSobreEscrutado
                        ).toLocaleString() + '%'
                      ">
                      <template v-slot:icon>
                        <v-icon size="50">$vuetify.icons.participacion</v-icon>
                      </template>
                    </Infocard>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>

              <v-col ref="partidoInforme" cols="12" v-if="showPartidos"  class="order-lg-0 order-sm-0 pt-1">
                <PartidoInforme :loading="loading" :customHeight="350" :cargo="informe.IdCargo" :distrito="informe.IdDistrito"  title="Agrupaciones políticas"
                  :agrupaciones="informe.agrupaciones"></PartidoInforme>
              </v-col>
            </v-row>

            <!-- <div v-if="breakpage(index)" class="html2pdf__page-break"></div> -->

            <v-row :class="{ 'mt-3': breakpage(index) }">
              <v-col :cols="showGraficos ? '6' : '12'" class="pt-1" v-if="showMapa">
                <MapaInforme :zoomOptions="false" :key="key" :loading="loading" :customHeight="370"
                  :dataParamsMap="dataParams[index]" :index="index" @loaded="loadedMapa" title="Ámbitos"></MapaInforme>
              </v-col>
              <v-col :cols="showMapa ? '6' : '12'" class="pt-1" v-if="showGraficos">
                <VotosInforme :loading="loading" :votos="votoTipos(informe)" :customHeight="370"
                  :width="showMapa ? '380px' : '65%'" :votantes="informe.Votantes" title="Resumen de votos">
                </VotosInforme>
              </v-col>
            </v-row>
          </template>




          <template v-else-if="index % 2 == 0">
            <v-row class="mt-0 pt-0">
              <v-col cols="6">
                <v-row>
                  <v-col col="12">
                    <v-breadcrumbs class="ml-0 pl-0 pb-0 pt-0 text-capitalize" divider="/"
                      :items="titleAndSubtitles(informe)">
                    </v-breadcrumbs>
                  </v-col>
                  <v-col cols="12" class="pt-2 pb-1">

                    <v-container>
                      <v-row class="pb-1 primary  white--text" style="  border-radius: 5px;">

                        <v-col cols="12" class="infoCard_content">
                          <div class="infoCard__body">
                            Mesas computadas {{ Number(informe.MesasEscrutadas).toLocaleString() }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>

                    <!-- <v-banner elevation="1" color="primary" rounded class="white--text">
                      Mesas computadas {{ Number(informe.MesasEscrutadas).toLocaleString() }}
                    </v-banner> -->
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-1">
                    <v-container>
                      <v-row class="pb-1 magenta  white--text" style="  border-radius: 5px;">

                        <v-col cols="12" class="infoCard_content">
                          <div class="infoCard__body">
                            Electores {{ Number(informe.Electores).toLocaleString() }}

                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-banner elevation="1" color="magenta" rounded class="white--text">
                      Electores {{ Number(informe.Electores).toLocaleString() }}
                    </v-banner> -->
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-1">
                    <v-container>
                      <v-row class="pb-1 lilac  white--text" style="  border-radius: 5px;">

                        <v-col cols="12" class="infoCard_content">
                          <div class="infoCard__body">
                            Participación sobre escrutado {{ Number(informe.ParticipacionSobreEscrutado).toLocaleString()
                            }}%

                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-banner elevation="1" color="lilac" rounded class="white--text">
                      Participación sobre escrutado {{ Number(informe.ParticipacionSobreEscrutado).toLocaleString() }}%
                    </v-banner> -->
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" v-if="data.length > index + 1">

                <v-row>
                  <v-col col="12">
                    <v-breadcrumbs class="ml-0 pl-0 pb-0 pt-0 text-capitalize" divider="/"
                      :items="titleAndSubtitles(data[index + 1])">
                    </v-breadcrumbs>
                  </v-col>
                  <v-col cols="12" class="pt-2 pb-1">
                    <v-container>
                      <v-row class="pb-1 primary  white--text" style="  border-radius: 5px;">

                        <v-col cols="12" class="infoCard_content">
                          <div class="infoCard__body">
                            Mesas computadas {{ Number((data[index + 1]).MesasEscrutadas).toLocaleString() }}

                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-banner elevation="1" color="primary" rounded class="white--text">
                      Mesas computadas {{ Number((data[index + 1]).MesasEscrutadas).toLocaleString() }}
                    </v-banner> -->
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-1">
                    <v-container>
                      <v-row class="pb-1 magenta  white--text" style="  border-radius: 5px;">

                        <v-col cols="12" class="infoCard_content">
                          <div class="infoCard__body">
                            <!-- Mesas computadas {{ Number((data[index + 1]).MesasEscrutadas).toLocaleString() }} -->
                            Electores {{ Number((data[index + 1]).Electores).toLocaleString() }}

                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-banner elevation="1" color="magenta" rounded class="white--text">
                      Electores {{ Number((data[index + 1]).Electores).toLocaleString() }}
                    </v-banner> -->
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-1">
                    <v-container>
                      <v-row class="pb-1 lilac  white--text" style="  border-radius: 5px;">

                        <v-col cols="12" class="infoCard_content">
                          <div class="infoCard__body">
                            <!-- Mesas computadas {{ Number((data[index + 1]).MesasEscrutadas).toLocaleString() }} -->
                            Participación sobre escrutado {{ Number((data[index +
                              1]).ParticipacionSobreEscrutado).toLocaleString() }}%
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-banner elevation="1" color="lilac" rounded class="white--text">
                      Participación sobre escrutado {{ Number((data[index +
                        1]).ParticipacionSobreEscrutado).toLocaleString() }}%
                    </v-banner> -->
                  </v-col>
                </v-row>
              </v-col>

            </v-row>

            <v-row v-if="showPartidos">
              <v-col cols="6" class="order-lg-0 order-sm-0 pt-1">
                <PartidoInforme :loading="loading" :customHeight="300" :cargo="informe.IdCargo" :distrito="informe.IdDistrito" title="Agrupaciones políticas"
                  :agrupaciones="informe.agrupaciones"></PartidoInforme>
              </v-col>

              <v-col cols="6" v-if="data.length > index + 1" class="order-lg-0 order-sm-0 pt-1">
                <PartidoInforme :loading="loading" :customHeight="300" :cargo="informe.IdCargo" :distrito="informe.IdDistrito"  title="Agrupaciones políticas"
                  :agrupaciones="data[index + 1].agrupaciones"></PartidoInforme>
              </v-col>
            </v-row>

            <!-- <div v-if="breakPageCompare(index)" class="html2pdf__page-break "></div> -->
            <div v-if="breakPageCompare(index)" class="mt-3"></div>



            <!-- <div v-if="breakpageCompare(index)" class="html2pdf__page-break"></div> -->
            <v-row v-if="showMapa">
              <v-col cols="6" class="pt-1">
                <MapaInforme :zoomOptions="false" :key="key" :loading="loading" :customHeight="340"
                  :dataParamsMap="dataParams[index]" :index="index" @loaded="loadedMapa" title="Ámbitos"></MapaInforme>
              </v-col>

              <v-col cols="6" class="pt-1" v-if="data.length > index + 1">
                <MapaInforme :zoomOptions="false" :key="key" :loading="loading" :customHeight="340"
                  :dataParamsMap="dataParams[index + 1]" :index="index + 1" @loaded="loadedMapa" title="Ámbitos">
                </MapaInforme>
              </v-col>
            </v-row>


            <!-- <div v-if="breakPageCompare2(index)" class="html2pdf__page-break "></div> -->
            <div v-if="breakPageCompare2(index)" class="mt-3"></div>

            <!-- <div v-if="breakpageCompare2(index)" class="html2pdf__page-break"></div> -->
            <v-row v-if="showGraficos">
              <v-col cols="6" class="pt-1">
                <VotosInforme :loading="loading" :votos="votoTipos(informe)" :customHeight="370" :width="'380px'"
                  :votantes="informe.Votantes" title="Resumen de votos"></VotosInforme>
              </v-col>
              <v-col cols="6" class="pt-1" v-if="data.length > index + 1">
                <VotosInforme :loading="loading" :votos="votoTipos(data[index + 1])" :customHeight="370" :width="'380px'"
                  :votantes="data[index + 1].Votantes" title="Resumen de votos"></VotosInforme>
              </v-col>
            </v-row>
          </template>


          <!-- <div v-if="(format == 1) ? breakFooter(index) : (index % 2 == 0) ? breakFooterCompare(index) : false"
            class="html2pdf__page-break"></div> -->

          <!-- <div v-if="breakFooter2(index)" class="html2pdf__page-break"></div> -->


          <v-row v-if="format == 1 || index % 2 == 0" class="mt-1 pt-1 mb-0 pb-0">
            <v-col cols="12" class="my-0 py-0">
              Resultados Provisorios en base a telegramas recibidos (Art. 43 Ley
              26571 y Art. 105 Ley 19945). Los porcentajes de las agrupaciones
              políticas son calculados sobre los votos válidos (PASO) conforme
              al art. 45 Ley 26.571 y afirmativos (Generales). No se incluyen
              los votos correspondientes a los electores residentes en el
              exterior ni privados de la libertad.
            </v-col>
          </v-row>

          <!-- <div v-if="
            ((format == 0 && (index + 1) % 2 == 0) || format == 1) &&
            data.length - 1 > index
          " class="html2pdf__page-break"></div> -->
        </v-container>
      </template>
    </v-main>
  </v-app>
</template>
  

<script >
import Infocard from "@/components/Infocard.vue";
import PartidoInforme from "@/components/informe/Partidoinforme.vue";
import MapaInforme from "@/components/informe/MapaInforme.vue";
import VotosInforme from "@/components/informe/Votosinforme.vue";
// import ResultadosService from "@/services/ResultadosService";
// import { mapActions } from "vuex";
// import { defineAsyncComponent } from "vue";

export default {
  name: "Informe",
  // components: { Infocard},
  components: { Infocard, PartidoInforme, MapaInforme, VotosInforme },

  props: {
    data: {
      type: Array,
    },

    dataParams: {
      type: Array,
    },
    options: {
      type: Array,
    },
    format: {
      type: Number,
    },
  },

  data() {
    return {

      key: 0
      // dataParams: null,
    };
  },

  computed: {
    showPartidos() {
      if (this.options.length) {
        return this.options.find((o) => o.value == 2)?.active;
      }
      return true;
    },

    showMapa() {
      if (this.options.length) {
        return this.options.find((o) => o.value == 0)?.active;
      }
      return true;
    },

    showGraficos() {
      if (this.options.length) {
        return this.options.find((o) => o.value == 1)?.active;
      }
      return true;
    },

    loading() {
      return this.data.length ? false : true;
    },
  },

  // mounted() {
  //   console.log("mounted data:", this.data);
  // },

  // created() {
  //   // console.log("initdata", this.data);

  //   // this.initData();

  //   console.log(this.data);
  // },

  methods: {

    // breakFooter(index) {

    //   if (!this.showPartidos) {
    //     return false
    //   }


    //   if (this.showGraficos || this.showMapa) {

    //     if (this.data[index].IdEleccion != 1) {
    //       // console.log('generales o ballotage');
    //       if (this.data[index].agrupaciones.length == 6 || this.data[index].agrupaciones.length == 5) {

    //         return true;
    //       } else {
    //         return false
    //       }

    //     } else {
    //       console.log("entro");
    //       let count = 0
    //       this.data[index].agrupaciones.forEach((a) => {
    //         count++;
    //         a.listas.forEach(() => count++)
    //       })

    //       if (count >= 6 && count <= 5) {
    //         return true;
    //       } else {
    //         return false
    //       }
    //       // console.log('paso');

    //     }
    //   } else {
    //     return false
    //   }
    // },

    breakFooter2(index) {

      if (!this.showPartidos) {
        return false
      }


      if (this.format == 1) {
        if (this.breakpage(index)) {
          return false
        }

      } else {
        if (this.breakPageCompare(index) || this.breakPageCompare2(index)) {
          return false
        }

      }




      if (this.showGraficos || this.showMapa) {

        if (this.data[index].IdEleccion != 1) {
          // console.log('generales o ballotage');

          let count = 0

          if (this.data[index + 1]) {
            if (this.data[index + 1].IdEleccion == 1) {
              this.data[index + 1].agrupaciones.forEach((a) => {
                count++;
                a.listas.forEach(() => count++)
              })
              if (this.data[index + 1].IdDistrito == 0) {
                count = count / 2
              }
            }
          }

          if (this.data[index].agrupaciones.length == 6 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0) == 6) {

            return true;
          } else {
            return false
          }

        } else {
          let count = 0
          this.data[index].agrupaciones.forEach((a) => {
            count++;
            a.listas.forEach(() => count++)
          })


          let count2 = 0
          if (this.data[index + 1]) {
            if (this.data[index + 1].IdEleccion == 1) {
              this.data[index + 1].agrupaciones.forEach((a) => {
                count2++;
                a.listas.forEach(() => count2++)
              })
              if (this.data[index + 1].IdDistrito == 0) {
                count2 = countcount2 / 2
              }
            }
          }


          if ((count <= 6 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0 <= 6) &&
            count >= 5 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) >= 5)) {
            return true;
          } else {
            return false
          }
          // console.log('paso');
          // return true
        }
      } else {
        return false
      }
    },

    breakpage(index) {

      // console.log(index, this.$refs);
      if (!this.showPartidos) {
        return false
      }

      // console.log(this.data[index]);
      if (this.data[index].IdEleccion != 1) {
        // console.log('generales o ballotage');


        if (this.showGraficos || this.showMapa) {

          if (this.data[index].agrupaciones.length > 6) {
            return true;
          } else {
            return false
          }
        } else {
          if (this.data[index].agrupaciones.length > 9) {
            return true;
          } else {
            return false
          }
        }

      } else {

        let count = 0
        this.data[index].agrupaciones.forEach((a) => {
          count++;
          a.listas.forEach(() => count++)
        })
        if (this.data[index].IdDistrito == 0) {
          count = count / 2
        }

        if (this.showGraficos || this.showMapa) {

          if (count > 6) {
            return true;
          } else {
            return false
          }

        } else {
          if (count > 9) {
            return true;
          } else {
            return false
          }
        }

        // return true
      }
    },


    breakPageCompare(index) {
      // console.log(this.data[index]);
      if (this.data[index].IdEleccion != 1) {
        // console.log('generales o ballotage');
        if (!this.showPartidos) {
          return false
        }
        let count = 0

        if (this.data[index + 1]) {
          if (this.data[index + 1].IdEleccion == 1) {
            this.data[index + 1].agrupaciones.forEach((a) => {
              count++;
              a.listas.forEach(() => count++)
            })
            if (this.data[index + 1].IdDistrito == 0) {
              count = count / 2
            }
          }
        }

        if (this.showGraficos && this.showMapa) {

          // console.log("🚀 ~ file: Informe.vue:461 ~ breakPageCompare ~ count:", count)

          if ((this.data[index].agrupaciones.length > 5 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0) > 5) &&
            this.data[index].agrupaciones.length <= 9 && ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0) <= 9) {
            return true;
          } else {
            return false
          }
        } else if (this.showGraficos ^ this.showMapa) {
          if (this.data[index].agrupaciones.length >= 9 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0) >= 9) {
            return true;
          } else {
            return false
          }
        }

      } else {

        let count = 0
        this.data[index].agrupaciones.forEach((a) => {
          count++;
          a.listas.forEach(() => count++)
        })
        if (this.data[index].IdDistrito == 0) {
          count = count / 2
        }

        let count2 = 0
        if (this.data[index + 1]) {
          if (this.data[index + 1].IdEleccion == 1) {
            this.data[index + 1].agrupaciones.forEach((a) => {
              count2++;
              a.listas.forEach(() => count2++)
            })
            if (this.data[index + 1].IdDistrito == 0) {
              count2 = countcount2 / 2
            }
          }
        }

        if (this.showGraficos || this.showMapa) {

          if ((count > 5 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) > 5) &&
            (count <= 9 && ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) <= 9)) {
            return true;
          } else {
            return false
          }

        } else {
          if (count >= 9 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) >= 9) {
            return true;
          } else {
            return false
          }
        }

      }
    },

    breakPageCompare2(index) {
      // console.log(this.data[index]);
      if (this.breakPageCompare(index)) {
        return false
      }
      if (this.data[index].IdEleccion != 1) {
        // console.log('generales o ballotage');
        if (!this.showPartidos) {
          return false
        }

        let count = 0

        if (this.data[index + 1]) {
          if (this.data[index + 1].IdEleccion == 1) {
            this.data[index + 1].agrupaciones.forEach((a) => {
              count++;
              a.listas.forEach(() => count++)
            })
            if (this.data[index + 1].IdDistrito == 0) {
              count = count / 2
            }
          }
        }

        if (this.showGraficos && this.showMapa) {


          if ((this.data[index].agrupaciones.length > 2 && this.data[index].agrupaciones.length < 6) &&
            (((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0) > 2 && ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0) < 6)) {
            // console.log("entro pla", (this.data[index].agrupaciones.length > 2 && this.data[index].agrupaciones.length < 6));
            return true;
          } else {
            return false
          }
        } else if (this.showGraficos ^ this.showMapa) {

          if (this.data[index].agrupaciones.length > 5 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count : this.data[index + 1].agrupaciones.length : 0)) {
            return true;
          } else {
            return false
          }
        }
        // else {
        //   if (this.data[index].agrupaciones.length > 9) {
        //     return true;
        //   } else {
        //     return false
        //   }
        // }

      } else {
        let count = 0
        this.data[index].agrupaciones.forEach((a) => {
          count++;
          a.listas.forEach(() => count++)
        })


        let count2 = 0
        if (this.data[index + 1]) {
          if (this.data[index + 1].IdEleccion == 1) {
            this.data[index + 1].agrupaciones.forEach((a) => {
              count2++;
              a.listas.forEach(() => count2++)
            })
            if (this.data[index + 1].IdDistrito == 0) {
              count2 = countcount2 / 2
            }
          }
        }


        if (this.showGraficos || this.showMapa) {

          if ((count > 2 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) > 2) &&
            (count <= 9 && ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) <= 9)) {
            return true;
          } else {
            return false
          }

        } else {
          if (count > 4 || ((this.data[index + 1]) ? (this.data[index + 1].IdEleccion == 1) ? count2 : this.data[index + 1].agrupaciones.length : 0) > 4) {
            return true;
          } else {
            return false
          }
        }

        // return true
      }
    },

    // breakFooterCompare(index) {

    //   if (!this.showPartidos) {
    //     return false
    //   }

    //   if (this.showGraficos || this.showMapa) {

    //     if (this.data[index].IdEleccion != 1) {
    //       // console.log('generales o ballotage');
    //       if (this.data[index].agrupaciones.length == 6 || this.data[index].agrupaciones.length == 5) {

    //         return true;
    //       } else {
    //         return false
    //       }

    //     } else {
    //       // console.log('paso');
    //       return true
    //     }
    //   } else {
    //     return false
    //   }
    // },

    votoTipos(data) {
      if (data) {
        return [
          { nombre: "Positivos", valor: data.positivos },
          { nombre: "En blanco", valor: data.blancos },
          { nombre: "Votos nulos", valor: data.nulos },
          {
            nombre: "Votos recurridos, impugnados y comando",
            valor: data.union,
          },
        ];
      } else {
        return {};
      }
    },

    titleAndSubtitles(data) {
      if (data) {
        let aux = [];
        aux.push({ text: "Elecciones " + data["Año"] });
        aux.push({ text: data["Elecciones"] });
        aux.push({
          // text: "Provisorio",

          text: data["Recuento"].toLowerCase(),
        });
        aux.push({
          // text: "Presidente/a",
          text: data["Cargo"].toLowerCase(),
        });
        aux.push({
          // text: "Buenos Aires",

          text: data["Distrito"].toLowerCase(),
        });
        if (data["SeccionProvincial"]) {
          aux.push({
            // text: "Seccion Capital",

            text: data["SeccionProvincial"].toLowerCase(),
          });
        }
        if (data["Seccion"]) {
          aux.push({
            // text: "La Plata",

            text: data["Seccion"].toLowerCase(),
          });
        }
        return aux;
      } else {
        return [];
      }
    },

    titles(data) {
      if (data) {
        let aux = [];
        aux.push("Elecciones " + data["Año"]);
        // aux.push("Elecciones " + "2021");
        aux.push(data["Elecciones"]);
        // aux.push("Generales");
        return aux;
      } else {
        return [];
      }
    },
    subtitles(data) {
      if (data) {
        let aux = [];
        aux.push({
          // text: "2021",
          text: data["Año"] + "",
        });
        aux.push({
          // text: "Provisorio",

          text: data["Recuento"].toLowerCase(),
        });
        aux.push({
          // text: "Presidente/a",
          text: data["Cargo"].toLowerCase(),
        });
        aux.push({
          // text: "Buenos Aires",

          text: data["Distrito"].toLowerCase(),
        });
        if (data["SeccionProvincial"]) {
          aux.push({
            // text: "Seccion Capital",

            text: data["SeccionProvincial"].toLowerCase(),
          });
        }
        if (data["Seccion"]) {
          aux.push({
            // text: "La Plata",

            text: data["Seccion"].toLowerCase(),
          });
        }
        return aux;
      } else {
        return [];
      }
    },

    loadedMapa(index) {
      this.$emit("loadedMapa", index);
      // console.log('mapaCargado');
    },
    // initData() {
    //   let data = {
    //     año: this.$route.params.periodo,
    //     recuento: "Provisorio",
    //     idEleccion: this.$route.params.eleccion,
    //     idCargo: this.$route.params.cargo,
    //     idDistrito: this.$route.params.distrito,
    //   };

    //   if (this.$route.params.seccionProvincial != "0") {
    //     data.idSeccionProvincial = this.$route.params.seccionProvincial;
    //   }

    //   if (this.$route.params.seccion) {
    //     data.idSeccion = this.$route.params.seccion;
    //   }

    //   this.dataParams = data;

    //   this.loading = true;
    //   this.getResultados(data).then((data) => {
    //     this.data = data;
    //     this.loading = false;
    //   });
    // },

    // getResultados: async (data) => {
    //   try {
    //     let dataFiltros = await ResultadosService.getTotalizados(data);
    //     return dataFiltros.data;
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // console.log("initdata", this.data);
    // },
  },

  watch: {
    data(act, prev) {
      this.key++;
      if (act.length && !this.showMapa) {
        this.$nextTick(() => {
          this.data.forEach((i, index) => {
            // console.log(i, index);
            this.$emit("loadedMapa", index);
          });
        });
      }
    },
  },

  // created() {

  //   console.log("created");
  //   console.log(this.$refs);
  //   console.log(this.$refs.app);
  // },

  // mounted() {
  //   console.log("before mount");
  //   this.$nextTick(() => {
  //     console.log(this.$refs);
  //     console.log(this.$refs.infoBox);
  //     // console.log(this.$refs.header);
  //   });
  // console.log(this.$refs.app);
  // },
  // beforeUpdate() {
  //   console.log("before update");
  //   console.log(this.$refs.app);
  // }
};
</script>

<style lang="scss">
.span_title {
  &::after {
    content: "|";
  }

  &:last-child::after {
    content: "";
  }
}

.v-breadcrumbs li:nth-child(even) {
  padding: 0 5px !important;
}

.informe {
  font-size: 12px;
}

.html2pdf__page-break {
  margin: 0 !important;
}
</style>