import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import "@/plugins/apexcharts";

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
//@ts-ignore
import { Icon } from 'leaflet';


import './veeValidate'
// delete Icon.Default.prototype._getIconUrl;
// Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.config.productionTip = false

Vue.filter('capitalize', function (value:any) {
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter ('cutwithdots', (text:string, caract:number) =>  {
  const dots = "...";
  if (text.length > caract) {
    text = text.substring (0,caract) + dots
  }
  return text
}),

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
