<template>
  <v-container fluid class="mt-3 px-5" v-if="data">
    <v-row class="d-flex justify-end pr-4" style="height:20px">
      <div v-if="!generateReport">
        <span role="button" @click="getResultadosCsv(dataParams)" class="tertiary--text font-weight-bold mr-3 fz-10">
          <v-icon small>mdi-file-download</v-icon> Descargar CSV
        </span>
      </div>
      <div v-if="generateReport">
        <span class="tertiary--text font-weight-bold mr-3 fz-10">
          <v-icon small>mdi-file-download</v-icon> Generando CSV
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </span>
      </div>
      <div>
        <a @click="setNewInformeData()" class="tertiary--text font-weight-bold ml-3 fz-10">
          <v-icon small>mdi-file</v-icon>Generar Informe
        </a>
      </div>
    </v-row>
    <v-row>
      <v-col md="6" lg="5" cols="12">
        <div class="font-weight-bold text-h4" v-if="!loading">
          <span class="span_title" v-for="(title, index) in titles" :key="index">
            {{ title }}
          </span>
        </div>
        <div v-else>
          <v-skeleton-loader type="heading"></v-skeleton-loader>
        </div>

        <div class="text-subtitle-1" v-if="!loading">
          <v-breadcrumbs class="ml-0 pl-0" divider=">" :items="subtitles">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="subtitles.at(-1) == item ? true : false">
                {{ item.text.toLowerCase() | capitalize }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>

        </div>
        <div v-else class="mt-4">
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>
      </v-col>
      <v-col md="6" lg="7">
        <v-row>
          <v-col sm="6" lg="4" cols="12">
            <Infocard :loading="loading" title="Mesas computadas"
              :description="Number(data.MesasEscrutadas).toLocaleString()">
              <template v-slot:icon>
                <v-icon size="50">$vuetify.icons.votar</v-icon>
              </template>
            </Infocard>
          </v-col>
          <v-col sm="6" lg="4" cols="12">
            <Infocard :loading="loading" title="Electores" color="magenta"
              :description="Number(data.Electores).toLocaleString()">
              <template v-slot:icon>
                <v-icon size="50">$vuetify.icons.comunidad</v-icon>
              </template>
            </Infocard>
          </v-col>
          <v-col cols="12" lg="4">
            <Infocard :loading="loading" title="Participación sobre escrutado" color="lilac" :description="Number(data.ParticipacionSobreEscrutado).toLocaleString() + '%'
              ">
              <template v-slot:icon>
                <v-icon size="50">$vuetify.icons.participacion</v-icon>
              </template>
            </Infocard>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="6" lg="4" cols="12" class="order-lg-0 order-sm-0">
        <CardPartido :loading="loading" title="Agrupaciones políticas" :agrupaciones="data.agrupaciones"></CardPartido>
      </v-col>
      <v-col sm="12" lg="4" cols="12" class="order-lg-1 order-sm-2">
        <CardMapa :loading="loading" :dataParamsMap="dataParams" title="Ámbitos"></CardMapa>
      </v-col>
      <v-col sm="6" lg="4" cols="12" class="order-lg-2 order-sm-1">
        <CardVotos :loading="loading" :votos="votoTipos" :votantes="data.Votantes" title="Resumen de votos"></CardVotos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script >
// import Vue from "vue";
import Infocard from "@/components/Infocard.vue";
import CardPartido from "@/components/cards/cardPartido.vue";
import CardMapa from "@/components/cards/cardMapa.vue";
import CardVotos from "@/components/cards/cardVotos.vue";
import ResultadosService from "@/services/ResultadosService";
import { mapState, mapActions } from "vuex";

export default {
  name: "Resultados",
  components: { Infocard, CardPartido, CardMapa, CardVotos },

  data() {
    return {
      generateReport: false,
      data: {},
      dataParams: null,
      loading: true,
    }
  },

  computed: {


    titles() {
      if (this.data) {
        let aux = [];
        aux.push("Elecciones " + this.data["Año"]);
        aux.push(this.data["Elecciones"]);

        return aux;
      } else {
        return [];
      }
    },

    subtitles() {
      if (this.data) {
        let aux = [];
        aux.push({ text: this.data["Año"] + "", href: "/" + this.data["Año"] });
        aux.push({
          text: this.data["Elecciones"].toLowerCase(),
          href: "/" + this.data["Año"],
        });
        aux.push({
          text: this.data["Recuento"].toLowerCase(),
          href: "/" + this.data["Año"],
        });
        aux.push({
          text: this.data["Cargo"].toLowerCase(),
          href:
            "/categorias/" +
            this.data["Año"] +
            "/" +
            this.data["IdEleccion"] +
            "/" +
            this.data["IdCargo"],
        });
        aux.push({
          text: this.data["Distrito"].toLowerCase(),
          href:
            "/resultados/" +
            this.data["Año"] +
            "/" +
            this.data["IdEleccion"] +
            "/" +
            this.data["IdCargo"] +
            "/" +
            this.data["IdDistrito"],
        });

        if (this.data["SeccionProvincial"]) {
          aux.push({
            text: this.data["SeccionProvincial"].toLowerCase(),
            href:
              "/resultados/" +
              this.data["Año"] +
              "/" +
              this.data["IdEleccion"] +
              "/" +
              this.data["IdCargo"] +
              "/" +
              this.data["IdDistrito"] +
              "/" +
              this.data["IDSeccionProvincial"],
          });
        }
        if (this.data["Seccion"]) {
          aux.push({
            text: this.data["Seccion"].toLowerCase(),
            href:
              "/resultados/" +
              this.data["Año"] +
              "/" +
              this.data["IdEleccion"] +
              "/" +
              this.data["IdCargo"] +
              "/" +
              this.data["IdDistrito"] +
              "/" +
              (this.data["IDSeccionProvincial"]
                ? this.data["IDSeccionProvincial"] + "/"
                : "0" + "/") +
              this.data["IdSeccion"],
          });
        }

        let lastobject = aux.at(-1);
        lastobject.disabled = true;

        // aux.push (this.data[0]["Elecciones"]);

        return aux;
      } else {
        return [];
      }
    },

    votoTipos() {
      if (this.data) {
        // let aux = [];

        return [
          { nombre: "Positivos", valor: this.data.positivos },
          { nombre: "En blanco", valor: this.data.blancos },
          { nombre: "Votos nulos", valor: this.data.nulos },
          {
            nombre: "Votos recurridos, impugnados y comando",
            valor: this.data.union,
          },
        ];
        // {positivos:this.data.positivos, blancos: this.data.blancos ,nulos:this.data.nulos,"otros nulos":this.union};
      } else {
        return {};
      }
    },

    // distrito () {
    //   if (this.data){
    //     return this.data.Distritos?.find (d => d.IdDistrito == this.$route.params.IdDistrito)
    //   }
    //   return {}
    // }
  },

  created() {
    // console.log("params en resultados", this.$route.params);
    // console.log("resultado", this.data);
    this.initData();
  },

  methods: {

    ...mapActions(["setNewInforme", "setInformeModal"]),


    setNewInformeData() {

      let data = {
        año: this.$route.params.periodo,
        idEleccion: this.$route.params.eleccion,
        idCargo: this.$route.params.cargo,
        idDistrito: this.$route.params.distrito,
      };

      if (this.$route.params.seccionProvincial != "0") {
        data.idSeccionProvincial = this.$route.params.seccionProvincial;
      }

      if (this.$route.params.seccion) {
        data.idSeccion = this.$route.params.seccion;
      }




      this.setInformeModal(true)
      this.$nextTick(() => {
        this.setNewInforme(data)
        // console.log(this.$store.state.newInforme);
      })
    },


    loadedMapa() {
      console.log('mapa cargado');
    },

    initData() {
      let data = {
        año: this.$route.params.periodo,
        recuento: "Provisorio",
        idEleccion: this.$route.params.eleccion,
        idCargo: this.$route.params.cargo,
        idDistrito: this.$route.params.distrito,
      };

      if (this.$route.params.seccionProvincial != "0") {
        data.idSeccionProvincial = this.$route.params.seccionProvincial;
      }

      if (this.$route.params.seccion) {
        data.idSeccion = this.$route.params.seccion;
      }

      this.dataParams = data;

      this.loading = true;
      this.getResultados(data).then((data) => {
        this.data = data;
        this.loading = false;
      });
    },

    getResultados: async (data) => {
      try {
        let dataFiltros = await ResultadosService.getTotalizados(data);

        return dataFiltros.data;
        // console.log(menu);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async getResultadosCsv(data) {
      try {
        this.generateReport = true
        let datacsv
        // if (data.idCargo == 1 && data.idDistrito == 0) {
        //   datacsv = await ResultadosService.getTotalizadosNacionalesCsv(data);
        // } else {
        //   datacsv = await ResultadosService.getTotalizadosCsv(data);
        // }
        datacsv = await ResultadosService.getTotalizadosCsv(data);

        let blob = new Blob([datacsv.data], { type: "application/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "presentacionDeResultados.csv";
        link.click();
        this.generateReport = false
      } catch (error) {
        this.generateReport = false
        console.log(error);
        throw error;
      }
    },
  },

  watch: {
    "$route.params": {
      handler: function (search) {
        this.initData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.span_title {
  &::after {
    content: "|";
  }

  &:last-child::after {
    content: "";
  }
}

.v-breadcrumbs li:nth-child(even) {
  padding: 0 5px !important;
}
</style>