<template>
  <div class="card cardmapa" :style="{ height: customHeight + 'px' }">
    <!-- <div class="card_title">
      {{ title }}
    </div> -->
    <div class="card_body">
      <l-map
        ref="mapControl"
        :center="center"
        v-if="show"
        :zoom="zoom"
        :options="{
          zoomControl: zoomOptions,
          attributionControl: false,
          scrollWheelZoom: false,
          dragging: !$vuetify.breakpoint.mobile,
        }"
        :style="{ height: customHeight - 10 + 'px', width: '100%' }"

      >
        <!-- :style="{ height: customHeight - 60 + 'px', width: '100%' }" -->

        <l-tile-layer :url="url" :attribution="attribution" />
        <l-geo-json
          :geojson="geojson"
          :options="options"
          :options-style="styleFunction"
        />
      </l-map>
    </div>
    <div class="card_footer"></div>
  </div>
</template>

<script>
//@ts-ignore
import { latLng, featureGroup } from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import MapasService from "@/services/MapasService";
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "cardMapa",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    dataParamsMap: {
      type: Object,
      default: null,
    },
    customHeight: {
      type: Number,
      default: 590,
    },
    zoomOptions: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    loading: false,
    show: true,
    enableTooltip: true,
    zoom: 10,
    center: [48, -1.219482],
    geojson: null,
    //fillColor: "#e4ce7f",
    url: "",// "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "",
    marker: latLng(47.41322, -1.219482),
    paso: 0,
    layerfocus: null,
  }),

  computed: {
    ...mapState(["mapas", "menu"]),

    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      // const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        this.paso++;
        return {
          weight: 2,
          color: "black",
          opacity: 1,
          fillOpacity: 1,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return {}; //() => {};
      }
      return (feature, layer) => {
        // console.log("feature", feature);
        layer.options.fillColor =
          feature?.properties?.agrupacion?.color || "#808080";

        // feature.properties.INDRA_D ==
        //     this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
        //       ? "#ee3d8f"
        //       : feature?.properties?.agrupacion?.color != undefined ? 'black': "#808080",

        if (this.dataParamsMap?.idSeccion) {
          layer.setStyle({
            color:
              feature.properties.INDRA_D ==
              this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
                ? "#ee3d8f"
                : "black",
            weight:
              feature.properties.INDRA_D ==
              this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
                ? 10
                : 1,
          });

          if (
            feature.properties.INDRA_D ==
            this.dataParamsMap?.idSeccion?.toString().padStart(3, 0)
          ) {
            this.layerfocus = layer;
            this.$nextTick().then(() => {
              // console.log("nextick del seccion id");
              let groupseccion = new featureGroup();
              groupseccion.addLayer(layer);

              this.$refs.mapControl.mapObject.fitBounds(
                groupseccion.getBounds(),
                {
                  padding: [0, 0],
                }
              );
            });
          }
        }

        let agrupacionProp = feature?.properties?.agrupacion;
        let agrupacionSegundaProp = feature?.properties?.segunda_fuerza;

        let mensaje = `
          <div><b>Distrito</b>: ${feature.properties.PROVINCIA}</div>
          ${feature?.properties?.DPTO ? "<div><b>Departamento</b>: " + feature.properties.DPTO + "</div>": "" }
          ${
            agrupacionProp
              ? "<div><b>1ª</b>: " +
                this.cutwithdots(agrupacionProp.nombre, 20) +
                "<br>" +
                agrupacionProp.votos.toLocaleString() +
                " Votos - " +
                agrupacionProp.porcentaje.toLocaleString() +
                "%" +
                "</div>"
              : ""
          }
          ${
            agrupacionSegundaProp
              ? "<div><b>2ª</b>: " +
                this.cutwithdots(agrupacionSegundaProp.nombre, 20) +
                " <br> " +
                agrupacionSegundaProp.votos.toLocaleString() +
                " Votos - " +
                agrupacionSegundaProp.porcentaje.toLocaleString() +
                "%" +
                "</div>"
              : ""
          }
        `;

        layer.bindTooltip(mensaje, { permanent: false, sticky: true });

        layer.on("click", (e) => {
          if (
            e?.target?.feature?.geometry?.coordinates &&
            e?.target?.feature?.properties.agrupacion
          ) {
            this.$nextTick().then(() => {
              var group = new featureGroup();
              group.addLayer(e?.target);

              this.$refs.mapControl?.mapObject.fitBounds(group.getBounds(), {
                padding: [40, 40],
              });
              // this.$refs.mapControl?.mapObject.setView(
              //   this.center,
              //   this.$refs.mapControl?.mapObject.getZoom(),
              //   {
              //     animate: false,
              //     pan: {
              //       duration: 10,
              //     },
              //   }
              // );

              //chequeo de q no haya ninguna layer marcada antes
              if (this.layerfocus) {
                this.layerfocus.setStyle({
                  color: "black",
                  weight: 1,
                });
              }

              //seteo esta layer como marcada
              this.layerfocus = layer;

              layer.setStyle({
                color: "#ee3d8f",
                weight: 10,
              });
              let paramsPush = {}
              if (!e?.target?.feature?.properties?.INDRA_D) {
                this.dataParamsMap.idDistrito = e?.target?.feature?.properties?.ID_INDRA
                paramsPush = {
                  periodo: parseInt(this.dataParamsMap.año),
                  eleccion: parseInt(this.dataParamsMap.idEleccion),
                  cargo: parseInt(this.dataParamsMap.idCargo),
                  distrito: parseInt(this.dataParamsMap.idDistrito),
                  norealoadMap: true
                }
              }else if (this.dataParamsMap.idSeccionProvincial != 0) {
                let sp = this.menu
                  .find((m) => m.Año == this.dataParamsMap.año)
                  .Cargos.find((c) => c.IdCargo == this.dataParamsMap.idCargo)
                  .Distritos.find(
                    (d) => d.IdDistrito == this.dataParamsMap.idDistrito
                  )
                  .SeccionesProvinciales.find((sp) =>
                    sp.Secciones.find(
                      (s) =>
                        s.IdSeccion ==
                        parseInt(e?.target?.feature?.properties?.INDRA_D)
                    )
                  );

                this.dataParamsMap.idSeccionProvincial = sp?.IDSeccionProvincial || 0;
                paramsPush = {
                  periodo: parseInt(this.dataParamsMap.año),
                  eleccion: parseInt(this.dataParamsMap.idEleccion),
                  cargo: parseInt(this.dataParamsMap.idCargo),
                  distrito: parseInt(this.dataParamsMap.idDistrito),
                  seccionProvincial: this.dataParamsMap.idSeccionProvincial
                    ? this.dataParamsMap.idSeccionProvincial
                    : 0,
                  seccion: e?.target?.feature?.properties?.INDRA_D ? 
                    parseInt(e?.target?.feature?.properties?.INDRA_D)
                    : 0,
                  norealoadMap: true
                }
              }

              this.$refs.mapControl.mapObject.doubleClickZoom.disable();

              router.push({
                name: "Resultados",
                params: paramsPush,
              });
            });
          }
        });
      };
    },
  },
  async created() {
    this.loading = true;
    // console.log("dataParamsMap", this.dataParamsMap);
    if (this.dataParamsMap) {
      this.getMapas().then(() => {
        this.loading = false;
      });
    }
  },

  methods: {
    cutwithdots(text, caract) {
      let dots = "...";
      if (text.length > caract) {
        text = text.substring(0, caract) + dots;
      }
      return text;
    },

    async getMapas() {
      if (this.dataParamsMap) {
        let dataMapa = await MapasService.getMapas({
          ...this.dataParamsMap,
          id_indra: this.dataParamsMap.idDistrito.toString().padStart(2, 0),
          tipo: "departamentos",
          minimizado: true,
        });
        // console.log("dataMapa", dataMapa.data);
        if (!dataMapa) {
          return;
        }

        // let mapaActual = dataMapa.data.find(mapa => mapa.ID_INDRA == this.dataParamsMap.idDistrito.toString().padStart(2, 0))
        this.geojson = dataMapa.data;

        if (this.geojson) {
          this.center = this.getCenterMap();
          // console.log(
          //   "🚀 ~ file: cardMapa.vue ~ line 295 ~ getMapas ~ this.center",
          //   this.center
          // );

          this.$nextTick().then(() => {
            var group = new featureGroup();
            // console.log(this.dataParamsMap);
            this.$refs.mapControl?.mapObject.eachLayer(function (layer) {
              if (layer.feature != undefined) group.addLayer(layer);
            });

            // console.log("dataparams", this.dataParamsMap);

            this.$refs.mapControl?.mapObject.fitBounds(group.getBounds(), {
              padding: [40, 40],
            });
            this.$refs.mapControl?.mapObject.setView(
              this.center,
              this.$refs.mapControl?.mapObject.getZoom(),
              {
                animate: false,
                pan: {
                  duration: 10,
                },
              }
            );

            this.$refs.mapControl?.mapObject.doubleClickZoom.disable();
            this.$emit("loaded", this.index);
          });
        }
      }
    },

    getCenterMap() {
      if (this.mapas) {
        let mapaContorno = this.mapas.find(
          (mapa) =>
            mapa.ID_INDRA ==
            this.dataParamsMap.idDistrito.toString().padStart(2, 0)
        );

        let allx = mapaContorno.features[0].geometry.coordinates[0].map(
          (x) => x[0]
        );
        let minx = Math.max.apply(null, allx);
        let maxx = Math.min.apply(null, allx);
        let centerx = (maxx - minx) / 2 + minx;

        let ally = mapaContorno.features[0].geometry.coordinates[0].map(
          (x) => x[1]
        );
        let miny = Math.max.apply(null, ally);
        let maxy = Math.min.apply(null, ally);
        let centery = (maxy - miny) / 2 + miny;
        // console.log("center", this.center);
        return [centery, centerx];
      } else {
        return [];
      }
    },
  },

  watch: {
    dataParamsMap() {
      if (!this.$route.params.norealoadMap) {
        this.loading = true;
        this.getMapas().then(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style lang="scss">
.leaflet-container {
  background: rgb(255, 255, 255) !important;
  outline: 0;
}
</style>