import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import votar from "@/assets/svg/votar.vue";
import participacion from "@/assets/svg/participacion.vue";
import comunidad from "@/assets/svg/comunidad.vue";

export default new Vuetify({


    icons: {
        values: {
            votar: {
                component: votar
            },
            participacion: {
                component: participacion
            },
            comunidad: {
                component: comunidad
            }
        }
    },


    theme: {
        themes: {
            light: {
                institucional: "#84D2F0",
                primary: '#18A0FB',
                secondary: "#37BBED",
                dark: "#333333",
                background: "#F4F8FF",
                magenta: "#EE3D8F",
                tertiary: "#5F6368",
                lilac: "#9283BE",
                button: "#1C6ED5",
                ballotage:'#D7DF23'

            }
        }
    }
});
