<template>
  <v-container>
    <v-row @click="collapse = !collapse">
      <v-col class="text-capitalize">
        <div class="roboto maxtext" :title="agrupacion.nombre">
          {{ agrupacion.nombre }}
        </div>
      </v-col>
      <v-col>
        <div class="roboto text-right">{{ agrupacion.porcentaje }}%</div>
        <div class="roboto text-right">
          {{ Number(agrupacion.votos).toLocaleString() }} votos
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="!(agrupacion && agrupacion.listas && agrupacion.listas[0].idlista)">
      <v-col cols="12">
        <v-progress-linear :value="agrupacion.porcentaje" rounded :color="agrupacion.color"
          height="15"></v-progress-linear>
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="mb-0" v-if="agrupacion && agrupacion.listas && agrupacion.listas[0].idlista">
      <v-col>
        <template>
          <v-divider></v-divider>
          <v-expand-transition>
            <div v-show="collapse" class="mt-2">
              <partido-progress v-for="(lista, index) in listasorder " :key="index" :partido="lista"
                :partidos="agrupacion.listas" :color="agrupacion.color" />
            </div>
          </v-expand-transition>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import partidoProgress from "./partidoProgress.vue";
export default {
  components: { partidoProgress },
  name: "cardAgrupacion",
  data() {
    return {
      show: !this.$vuetify.breakpoint.mobile,
      collapse: false,
    };
  },
  props: {
    agrupacion: {
      type: Object,
    },
    colapsar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},

  computed: {

    listasorder() {
      return this.agrupacion.listas.sort(function (a, b) {

        if (b.Votos > a.Votos) {
          return 1
        }
        if (b.Votos < a.Votos) {
          return -1;
        }
        // a must be equal to b
        return 0;

      });
    }
  },

  created() {
    this.collapse = this.colapsar;
  },

  watch: {
    colapsar() {
      this.collapse = this.colapsar;
    },
  },
};
</script>

<style>
.maxtext {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>