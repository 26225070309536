import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || null,
    periodo: sessionStorage.getItem('periodo') || null,
    periodos: JSON.parse(sessionStorage.getItem('periodos') !),
 
    search: {
      periodo: String,
      eleccion:String,
      cargo:String,
      distrito:String,
      seccionElectoral:String,
      seccion:String
    },


    newInforme : {},

    modalfilter: false,

    mapas:  JSON.parse(sessionStorage.getItem('mapas')!),
    menu:  JSON.parse(sessionStorage.getItem('menu')!),

    informeModal: false,

    informes: JSON.parse (localStorage.getItem('informes') !) || []

  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
      localStorage.setItem("token", payload)
    },

    setPeriodo (state, payload) {
      state.periodo = payload
      sessionStorage.setItem("periodo",payload)
    },

    setPeriodos (state, payload) {
      // console.log('setperiodos', payload);    
      state.periodos = payload
      sessionStorage.setItem("periodos",JSON.stringify(payload))
    },

    setMapas (state,data) {
      state.mapas = data
      sessionStorage.setItem("mapas",JSON.stringify(data))
    },

    setMenu (state,data) {
      state.menu = data
      sessionStorage.setItem("menu",JSON.stringify(data))
    },

    setInformes (state,informes) {
      state.informes = informes
      localStorage.setItem('informes',JSON.stringify(informes))
    },

    setModalfilter (state,data) {
      state.modalfilter = data
    } ,

    setNewInforme (state,data) {
      state.newInforme = data
    } ,

    setInformeModal (state,value) {
      state.informeModal = value
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setPeriodo ({commit}, periodo) {
      commit('setPeriodo',periodo)
    },
    setPeriodos ({commit}, periodos) {
      commit('setPeriodos',periodos)
    },
    setMapas ({commit},mapas) {
      commit('setMapas',mapas)
    },
    setMenu ({commit},menu) {
      commit('setMenu',menu)
    },

    setModalfilter({commit},modal) {
      commit('setModalfilter',modal)
    },

    setNewInforme ({commit},value) {
      commit('setNewInforme', value)
    },

    setInformeModal ({commit},value) {
      commit('setInformeModal', value)
    },

    setInformes ({commit}, informes) {
      commit('setInformes',informes)
    }
  },
  modules: {
  },
  getters: {
    isAuthenticated(state) {
      return state.token != null
    },

    getNewInforme (state) {
      return state.newInforme
    }
  }
})
